import React from "react";
import {useSelector} from "react-redux";
import {RootStore} from "../../store/Store";
import {BreadcrumbItem} from "../../store/breadcrumb/actions/BreadcrumbActionTypes";
import XDriveBreadCrumbItem from "./XDriveBreadcrumbItem";

/** Contains all the breadcrumbs. Reads the state of the store and shows the breadcrumb based on the amount in the store. */
const XDriveBreadcrumb = () => {
    const breadcrumbs = useSelector((state: RootStore) => state.breadcrumb.data || []);
    return (
        <React.Fragment>
            <div className="pulse-breadcrumb-wrapper">
                {breadcrumbs.map((el: BreadcrumbItem, index: number) => {
                    return (
                        <React.Fragment key={index}>
                            <XDriveBreadCrumbItem {...el} />
                        </React.Fragment>
                    );
                })}
            </div>
        </React.Fragment>
    );
};

export default XDriveBreadcrumb;
