import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const CREATE_DIRECTORY_STORE_STATE = createStoreState("create_directory");

interface CreateDirectoryLoading extends StoreServiceData<string> {
    type: typeof CREATE_DIRECTORY_STORE_STATE.LOADING;
}
interface CreateDirectoryError extends StoreServiceData<string> {
    type: typeof CREATE_DIRECTORY_STORE_STATE.ERROR;
}
interface CreateDirectorySuccess extends StoreServiceData<string> {
    type: typeof CREATE_DIRECTORY_STORE_STATE.SUCCESS;
}

export type CreateDirectoryDispatchTypes =
    | CreateDirectorySuccess
    | CreateDirectoryError
    | CreateDirectoryLoading;
