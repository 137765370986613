import {UPLOAD_MANAGER_STORE_STATE} from "../actions/UploadManagerActionTypes";
import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {UploadManagerFileItem} from "../../../components/UploadManager/Helpers/uploadManagerHelpers";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<UploadManagerFileItem[]>([]);

const uploadManagerReducer = (
    state: StoreServiceData<UploadManagerFileItem[]> = defaultState,
    action: ServiceActionTypes<UploadManagerFileItem[]>
): StoreServiceData<UploadManagerFileItem[]> =>
    createReducer(state, action, UPLOAD_MANAGER_STORE_STATE, () => showErrorToast(action.error));

export default uploadManagerReducer;
