import {Dispatch} from "redux";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import XDriveApiModel from "../../apiModel/XDriveApiModel";
import {PathReference} from "../../../api/xdrive";
import {DELETE_FILE_STORE_STATE, DeleteFileDispatchTypes} from "./DeleteFileActionTypes";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Clears Store of stale data */
export const nullifyDeleteFileStore = () => async (dispatch: Dispatch<DeleteFileDispatchTypes>) => {
    dispatch({
        type: DELETE_FILE_STORE_STATE.SUCCESS,
        error: null,
        loading: false,
        data: null
    });
};

/** Calls to the service to allow for a file to be deleted. */
export const deleteFile =
    (path: PathReference) => async (dispatch: Dispatch<DeleteFileDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                DELETE_FILE_STORE_STATE,
                dispatch,
                () => XDriveApiModel.getXDriveApi().deleteFile(path),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: DELETE_FILE_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
