import React from "react";
import {UploadItemState} from "../Helpers/uploadManagerHelpers";

const LoadingState = ({state, percentage}: LoadingStateProps) => {
    return (
        <React.Fragment>
            {state} - ({percentage.toFixed(0)}%)
            {state === UploadItemState.Uploading && (
                <React.Fragment>
                    <div className="loading-bar-wrapper">
                        <div
                            className="loading-bar stripey"
                            style={{width: `${percentage.toFixed(0)}%`}}
                        />
                    </div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default LoadingState;

interface LoadingStateProps {
    state: UploadItemState;
    percentage: number;
}
