import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const RENAME_DIRECTORY_STORE_STATE = createStoreState("rename_directory");

interface RenameDirectoryLoading extends StoreServiceData<string> {
    type: typeof RENAME_DIRECTORY_STORE_STATE.LOADING;
}
interface RenameDirectoryError extends StoreServiceData<string> {
    type: typeof RENAME_DIRECTORY_STORE_STATE.ERROR;
}
interface RenameDirectorySuccess extends StoreServiceData<string> {
    type: typeof RENAME_DIRECTORY_STORE_STATE.SUCCESS;
}

export type RenameDirectoryDispatchTypes =
    | RenameDirectoryLoading
    | RenameDirectoryError
    | RenameDirectorySuccess;
