import {Dispatch} from "redux";
import {
    UPLOADED_FILE_LIST_STATE,
    UploadedFile,
    UploadFileListDispatchTypes
} from "./UploadedFileListActionTypes";
import store, {RootStore} from "../../Store";
import {FormErrors} from "../../../utils/formUtils";
import {getItemName} from "../../../components/Pages/XDrive/Helpers/directorySortingHelpers";
import {pushUploadToManager} from "../../uploadManager/actions/UploadManagerActions";
import {showErrorToast} from "../../../utils/toastUtils";

/** Nullifies store */
export const nullifyUploadFileListStore = () => {
    return async (dispatch: Dispatch<UploadFileListDispatchTypes>) => {
        dispatch({
            type: UPLOADED_FILE_LIST_STATE.SUCCESS,
            error: null,
            loading: false,
            data: []
        });
    };
};

/** Sets store */
export const setUploadFileListStore = (files: UploadedFile[]) => {
    return async (dispatch: Dispatch<UploadFileListDispatchTypes>) => {
        dispatch({
            type: UPLOADED_FILE_LIST_STATE.SUCCESS,
            error: null,
            loading: false,
            data: files
        });
    };
};

/** Sets store */
export const setFileInListStore = (file: UploadedFile, index: number) => {
    return async (dispatch: Dispatch<UploadFileListDispatchTypes>, state: () => RootStore) => {
        const files = state().uploadedFileList.data || [];

        if (files.length === 0) return;

        files[index] = file;
        dispatch({
            type: UPLOADED_FILE_LIST_STATE.SUCCESS,
            error: null,
            loading: false,
            data: files
        });
    };
};

/** removes from store */
export const removeFileInListStore = (index: number) => {
    return async (dispatch: Dispatch<UploadFileListDispatchTypes>, state: () => RootStore) => {
        const files = state().uploadedFileList.data || [];

        if (files.length === 0) return;

        files.splice(index, 1);
        dispatch({
            type: UPLOADED_FILE_LIST_STATE.SUCCESS,
            error: null,
            loading: false,
            data: files
        });
    };
};

/** Validates and saves files */
export const validateAndSaveUploadedFiles = (directoryLocation: string) => {
    return async (dispatch: Dispatch<UploadFileListDispatchTypes>, state: () => RootStore) => {
        const files = state().uploadedFileList.data || [];

        if (files.length === 0) return false;
        const valid = validateFile(files);
        //
        if (!valid.valid) {
            for (const message of valid.messages) {
                showErrorToast(message);
            }
            return false;
        }

        for (let i = 0; i < files.length; ++i) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(pushUploadToManager(files[i], directoryLocation));
        }
        return true;
    };
};

/** Validates uploaded files */
function validateFile(files: UploadedFile[]): FormErrors {
    let valid = true;
    const messages: string[] = [];

    for (const file of files) {
        const name = getItemName(file.name);
        if (name.length < 3) {
            messages.push(`${file.name} must be longer than 2 characters.`);
            valid = false;
        }
        const format = /[!@#$%^&*()+=\[\]{};':"\\|,.<>\/?]+/;

        if (format.test(name)) {
            messages.push(`${file.name} has special characters, please remove them.`);
            valid = false;
        }
    }

    return {
        messages,
        valid
    };
}
