import {FILE_DOWNLOAD_STORE_STATE, FileDownloadDispatchTypes} from "./FileDownloadActionTypes";
import {Dispatch} from "redux";
import {getDataFromServiceWithRedux} from "store-fetch-wrappers";
import XDriveApiModel from "../../apiModel/XDriveApiModel";
import Store from "../../Store";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Clears store of stale data */
export const nullifyFileDownloadStore =
    () => async (dispatch: Dispatch<FileDownloadDispatchTypes>) => {
        dispatch({
            type: FILE_DOWNLOAD_STORE_STATE.SUCCESS,
            loading: false,
            error: null,
            data: null
        });
    };

/** Gets link to file download. File should automatically download due to the attribute:
 * Content: Disposition on the file in S3*/
export const getFileDownloadLink =
    (path: string) => async (dispatch: Dispatch<FileDownloadDispatchTypes>) => {
        try {
            const success = await getDataFromServiceWithRedux(
                FILE_DOWNLOAD_STORE_STATE,
                dispatch,
                () => XDriveApiModel.getXDriveApi().getFileLink({path}),
                statusCodeCallback
            );

            if (success) {
                const file = Store.getState().fileDownload.data;

                if (!file) return;

                return file;
            }
        } catch (e: any) {
            dispatch({
                type: FILE_DOWNLOAD_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
