import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {RENAME_DIRECTORY_STORE_STATE} from "../actions/RenameDirectoryActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<string>(null);

const renameDirectoryReducer = (
    state: StoreServiceData<string> = defaultState,
    action: ServiceActionTypes<string>
): StoreServiceData<string> =>
    createReducer(state, action, RENAME_DIRECTORY_STORE_STATE, () => showErrorToast(action.error));

export default renameDirectoryReducer;
