import {Dispatch} from "redux";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import XDriveApiModel from "../../apiModel/XDriveApiModel";
import {PathReference} from "../../../api/xdrive";
import {
    DELETE_DIRECTORY_STORE_STATE,
    DeleteDirectoryDispatchTypes
} from "./DeleteDirectoryActionTypes";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Clears Store of stale data */
export const nullifyDeleteDirectoryStore =
    () => async (dispatch: Dispatch<DeleteDirectoryDispatchTypes>) => {
        dispatch({
            type: DELETE_DIRECTORY_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };

/** Calls to the service to allow for a directory to be deleted. */
export const deleteDirectory =
    (path: PathReference, breadcrumb: string) =>
    async (dispatch: Dispatch<DeleteDirectoryDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                DELETE_DIRECTORY_STORE_STATE,
                dispatch,
                () => XDriveApiModel.getXDriveApi().deleteDirectory(path),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: DELETE_DIRECTORY_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
            return false;
        }
    };
