import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {RAW_DIRECTORY_LIST_STORE_STATE} from "../actions/RawDirectoryListActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<string[]>([]);

const rawDirectoryListReducer = (
    state: StoreServiceData<Array<string>> = defaultState,
    action: ServiceActionTypes<Array<string>>
): StoreServiceData<string[]> =>
    createReducer(state, action, RAW_DIRECTORY_LIST_STORE_STATE, () =>
        showErrorToast(action.error)
    );

export default rawDirectoryListReducer;
