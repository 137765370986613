import {combineReducers} from "redux";
import authReducer from "./auth/reducer/AuthReducer";
import navigationReducer from "./navigation/reducer/NavigationReducer";
import sidebarReducer from "./sidebar/reducer/sidebarReducer";
import mcConfigReducer from "./mcConfig/reducer/McConfigReducer";
import rawDirectoryListReducer from "./rawDirectoryList/reducer/RawDirectoryListReducer";
import fileDownloadReducer from "./fileDownload/reducer/FileDownloadReducer";
import breadcrumbReducer from "./breadcrumb/reducer/BreadcrumbReducer";
import renameDirectoryReducer from "./renameDirectory/reducer/RenameDirectoryReducer";
import renameFileReducer from "./renameFile/reducer/RenameFileReducer";
import deleteFileReducer from "./deleteFile/reducer/DeleteFileReducer";
import deleteDirectoryReducer from "./deleteDirectory/reducer/DeleteDirectoryReducer";
import saveFileReducer from "./saveFile/reducer/SaveFileReducer";
import uploadManagerReducer from "./uploadManager/reducer/UploadManagerReducer";
import uploadManagerNotificationsReducer from "./uploadManagerNotifications/reducer/UploadManagerNotificationsReducer";
import createDirectoryReducer from "./createDirectory/reducer/CreateDirectoryReducer";
import uploadedFileListReducer from "./uploadedFileList/reducer/UploadedFileListReducer";

const RootReducer = combineReducers({
    navigation: navigationReducer,
    sidebar: sidebarReducer,
    auth: authReducer,
    mcConfig: mcConfigReducer,
    rawDirectoryList: rawDirectoryListReducer,
    fileDownload: fileDownloadReducer,
    breadcrumb: breadcrumbReducer,
    renameDirectory: renameDirectoryReducer,
    renameFile: renameFileReducer,
    deleteFile: deleteFileReducer,
    deleteDirectory: deleteDirectoryReducer,
    saveFile: saveFileReducer,
    createDirectory: createDirectoryReducer,
    uploadedFileList: uploadedFileListReducer,
    uploadManager: uploadManagerReducer,
    uploadManagerNotifications: uploadManagerNotificationsReducer
});

export default RootReducer;
