import {RENAME_FILE_STORE_STATE, RenameFileDispatchTypes} from "./RenameFileActionTypes";
import {Dispatch} from "redux";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import XDriveApiModel from "../../apiModel/XDriveApiModel";
import {RenameReference} from "../../../api/xdrive";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Clears store of stale data */
export const nullifyRenameFileStore = () => async (dispatch: Dispatch<RenameFileDispatchTypes>) => {
    dispatch({
        type: RENAME_FILE_STORE_STATE.SUCCESS,
        error: null,
        loading: false,
        data: null
    });
};

/** Calls to the service to allow for a file to be renamed. */
export const renameFile =
    (args: RenameReference) => async (dispatch: Dispatch<RenameFileDispatchTypes>) => {
        try {
            return await postDataToServiceWithRedux(
                RENAME_FILE_STORE_STATE,
                dispatch,
                () => XDriveApiModel.getXDriveApi().renameFile(args),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: RENAME_FILE_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
