import React, {useEffect, useState} from "react";
import DirectoryListItem from "./DirectoryListItem";
import {useHistory} from "react-router-dom";
import {useBreadcrumb} from "../../../Hooks/useBreadcrumb";
import {routeNames} from "../../../Navigation/routeNames";
import {useDispatch} from "react-redux";
import {toArray} from "../../../../utils/sortingUtils";
import {getDirectoriesForLocation} from "../../../../store/rawDirectoryList/actions/RawDirectoryListActions";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {Modal, useModal} from "pulse-modal";
import CreateDirectoryForm from "./CreateDirectoryForm";

const DirectoryContainer = (props: Array<string>) => {
    const [directoryItems, setDirectoryItems] = useState<Array<string>>(toArray(props));
    const {isShown, toggle} = useModal();
    const dispatch = useDispatch();

    const history = useHistory();
    const breadcrumb = useBreadcrumb();

    useEffect(() => {
        setDirectoryItems(toArray(props));
    }, [props]);

    const newFile = () => {
        const parsedBreadCrumb = breadcrumb === "" ? "Home Folder/" : breadcrumb;
        const search = `?dirLoc=${parsedBreadCrumb}`;
        history.push({pathname: routeNames.uploadFile.path, search});
    };

    const refreshDirectory = () => {
        dispatch(getDirectoriesForLocation(breadcrumb));
    };

    return (
        <React.Fragment>
            <div className="row ml-0 mr-0 mt-3 mb-3">
                <div className="col d-flex justify-content-end pr-0">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Refresh"}
                        onClick={refreshDirectory}
                        colour={ButtonColourOptions.DarkBlue}
                    />
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"New Folder"}
                        onClick={toggle}
                        colour={ButtonColourOptions.DarkBlue}
                    />
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"New File"}
                        onClick={newFile}
                        colour={ButtonColourOptions.DarkBlue}
                    />
                </div>
            </div>
            {directoryItems.length > 0 ? (
                <div className="row ml-0 mr-0">
                    {directoryItems.length > 0 && (
                        <React.Fragment>
                            {directoryItems.map((el: string, index: number) => {
                                return <DirectoryListItem item={el} key={index} />;
                            })}
                        </React.Fragment>
                    )}
                </div>
            ) : (
                <div className="row ml-0 mr-0" style={{minHeight: 700}} />
            )}
            <Modal
                modalSize={"sm"}
                title={"New Folder"}
                bodyChildren={<CreateDirectoryForm onClose={toggle} items={directoryItems} />}
                onClose={toggle}
                isShown={isShown}
            />
        </React.Fragment>
    );
};

export default DirectoryContainer;

export interface CurrentDirectoryItems {
    items: string[];
}
