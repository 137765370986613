import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {FILE_DOWNLOAD_STORE_STATE} from "../actions/FileDownloadActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<string>(null);

const fileDownloadReducer = (
    state: StoreServiceData<string> = defaultState,
    action: ServiceActionTypes<string>
): StoreServiceData<string> =>
    createReducer(state, action, FILE_DOWNLOAD_STORE_STATE, () => showErrorToast(action.error));

export default fileDownloadReducer;
