import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const FILE_DOWNLOAD_STORE_STATE = createStoreState("file_download");

interface FileDownloadLoading extends StoreServiceData<string> {
    type: typeof FILE_DOWNLOAD_STORE_STATE.LOADING;
}
interface FileDownloadError extends StoreServiceData<string> {
    type: typeof FILE_DOWNLOAD_STORE_STATE.ERROR;
}
interface FileDownloadSuccess extends StoreServiceData<string> {
    type: typeof FILE_DOWNLOAD_STORE_STATE.SUCCESS;
}

export type FileDownloadDispatchTypes =
    | FileDownloadError
    | FileDownloadLoading
    | FileDownloadSuccess;
