import React from "react";
import {MenuItem} from "react-contextmenu";
import {Modal, useModal} from "pulse-modal";
import MCButton, {ButtonColourOptions, ButtonSize} from "../Button/MCButton";
import {Loading} from "store-fetch-wrappers";
import {getDirectoriesForLocation} from "../../store/rawDirectoryList/actions/RawDirectoryListActions";
import {useBreadcrumb} from "../Hooks/useBreadcrumb";
import {useDispatch} from "react-redux";

const ContextMenuItemWithDeleteAction = ({
    menuItemName,
    modalTitle,
    modalBodyText,
    onConfirm,
    loading
}: ContextMenuItemWithDeleteActionProps) => {
    const {toggle, isShown} = useModal();
    const breadcrumb = useBreadcrumb();
    const dispatch = useDispatch();
    return (
        <React.Fragment>
            {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                <MenuItem onClick={toggle}>
                    <span>{menuItemName}</span>
                </MenuItem>
            }
            <Modal
                modalSize={"sm"}
                title={modalTitle}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <p className="mb-0">{modalBodyText}</p>
                        </div>
                        <div className="row ml-0 mr-0">
                            <div className="col text-center">
                                {loading && <Loading type={"three-ring"} showLoadingText={false} />}
                            </div>
                        </div>
                    </React.Fragment>
                }
                onClose={toggle}
                isShown={isShown}
                footerChildren={
                    <div className="row ml-0 mr-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Yes"}
                            onClick={async () => {
                                const success = await onConfirm();
                                if (!success) return;
                                dispatch(getDirectoriesForLocation(breadcrumb));
                                toggle();
                            }}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"No"}
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default ContextMenuItemWithDeleteAction;

interface ContextMenuItemWithDeleteActionProps {
    menuItemName: string;
    modalBodyText: string;
    onConfirm: () => Promise<boolean>;
    modalTitle: string;
    loading: boolean;
}
