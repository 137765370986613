import React, {useContext} from "react";
import jpg from "../../../../images/jpg.png";
import pdf from "../../../../images/pdf.png";
import file from "../../../../images/file.png";
import wordDoc from "../../../../images/doc.png";
import excelDoc from "../../../../images/xls.png";
import pptDoc from "../../../../images/ppt.png";
import folder from "../../../../images/folder.png";
import {ContextMenu, ContextMenuTrigger, MenuItem} from "react-contextmenu";
import {nanoid} from "nanoid";
import {useDispatch, useSelector} from "react-redux";
import {getFileDownloadLink} from "../../../../store/fileDownload/actions/FileDownloadActions";
import {pushToBreadCrumb} from "../../../../store/breadcrumb/actions/BreadcrumbActions";
import {getItemName} from "../Helpers/directorySortingHelpers";
import DirectoryItemContext from "../../../Context/DirectoryItemContext";
import {getDirectoriesForLocation} from "../../../../store/rawDirectoryList/actions/RawDirectoryListActions";
import ContextMenuItemWithDeleteAction from "../../../ContextMenu/ContextMenuItemWithDeleteAction";
import {deleteDirectory} from "../../../../store/deleteDirectory/actions/DeleteDirectoryActions";
import {PathReference} from "../../../../api/xdrive";
import {useBreadcrumb} from "../../../Hooks/useBreadcrumb";
import {RootStore} from "../../../../store/Store";
import ContextMenuItemWithRenameAction, {
    RenameArgs
} from "../../../ContextMenu/ContextMenuItemWithRenameAction";
import {renameDirectory} from "../../../../store/renameDirectory/actions/RenameDirectoryActions";
import {deleteFile} from "../../../../store/deleteFile/actions/DeleteFileActions";
import {renameFile} from "../../../../store/renameFile/actions/RenameFileActions";
import {showSuccessToast} from "../../../../utils/toastUtils";

/** Shows the correct icon for the type of file. */
const FileIcon = (props: FileIconProps) => {
    const iconId = nanoid();
    const directoryItem = useContext(DirectoryItemContext);
    const dispatch = useDispatch();
    const breadcrumb = useBreadcrumb();

    const deleteDirectoryLoading = useSelector((state: RootStore) => state.deleteDirectory.loading);
    const renameDirectoryLoading = useSelector((state: RootStore) => state.renameDirectory.loading);
    const deleteFileLoading = useSelector((state: RootStore) => state.deleteFile.loading);
    const renameFileLoading = useSelector((state: RootStore) => state.renameFile.loading);

    /** Switch case to render the correct icon based on file extension. */
    const renderFileIcon = (fileExt: string) => {
        switch (fileExt.toLowerCase()) {
            case ".doc":
            case ".docx":
                return <img alt="" className="interactive" height="86" width="86" src={wordDoc} />;
            case ".pdf":
                return <img alt="" className="interactive" height="86" width="86" src={pdf} />;
            case ".jpg":
            case ".jpeg":
            case ".png":
            case ".gif":
                return <img alt="" className="interactive" height="86" width="86" src={jpg} />;
            case ".ppt":
            case ".pptx":
                return <img alt="" className="interactive" height="86" width="86" src={pptDoc} />;
            case ".xls":
            case ".xlsx":
                return <img alt="" className="interactive" height="86" width="86" src={excelDoc} />;
            case "folder":
                return <img alt="" className="interactive" height="86" width="86" src={folder} />;
            default:
                return <img alt="" className="interactive" height="86" width="86" src={file} />;
        }
    };

    /** Renders different dropdown based on directory item type */
    const renderMenuOptions = (path: string) => {
        if (path.endsWith("/")) {
            return (
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                <ContextMenu id={iconId}>
                    <ContextMenuItemWithDeleteAction
                        menuItemName={"Delete"}
                        modalTitle={"Delete Folder"}
                        loading={deleteDirectoryLoading}
                        modalBodyText={`Are you sure you want to delete ${getItemName(
                            directoryItem.item
                        )}?`}
                        onConfirm={async () => await deleteFolder({path: directoryItem.item})}
                    />
                    <div className="row-separator" />
                    <ContextMenuItemWithRenameAction
                        renameType={"Folder"}
                        menuItemName={"Rename"}
                        loading={renameDirectoryLoading}
                        modalTitle={"Rename Folder"}
                        onConfirm={async (renameArgs: RenameArgs) => await renameFolder(renameArgs)}
                    />
                </ContextMenu>
            );
        }

        return (
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            <ContextMenu id={iconId}>
                <ContextMenuItemWithDeleteAction
                    menuItemName={"Delete"}
                    modalTitle={"Delete File"}
                    loading={deleteFileLoading}
                    modalBodyText={`Are you sure you want to delete ${getItemName(
                        directoryItem.item
                    )}?`}
                    onConfirm={async () => await deleteFileRequest({path: directoryItem.item})}
                />
                <div className="row-separator" />
                <ContextMenuItemWithRenameAction
                    renameType={"File"}
                    menuItemName={"Rename"}
                    loading={renameFileLoading}
                    modalTitle={"Rename File"}
                    onConfirm={async (renameArgs: RenameArgs) =>
                        await renameFileRequest(renameArgs)
                    }
                />
                <div className="row-separator" />
                {() => {
                    return (
                        // Wrapped like this to allow for ts-ignore
                        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                        // @ts-ignore
                        <MenuItem onClick={downloadFileRequest}>
                            <span>Download</span>
                        </MenuItem>
                    );
                }}
            </ContextMenu>
        );
    };

    /** Allows for the file to be downloaded. */
    const downloadFileRequest = async () => {
        const filePath = directoryItem.item;

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const downloadedFile: string = await dispatch(getFileDownloadLink(filePath));

        const link = document.createElement("a");
        link.href = downloadedFile;
        link.setAttribute("download", getItemName(directoryItem.item));
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    /** Allows for use to enter a directory */
    const enterDirectory = async () => {
        //Accounts for if the item is a file.
        if (!directoryItem.item.endsWith("/")) {
            await downloadFileRequest();
            return;
        }
        dispatch(getDirectoriesForLocation(directoryItem.item));
        dispatch(
            pushToBreadCrumb({
                relativePath: `${getItemName(directoryItem.item)}/`,
                uiFriendlyName: getItemName(directoryItem.item),
                breadcrumbId: nanoid(),
                absolutePath: directoryItem.item
            })
        );
    };

    const deleteFolder = async (request: PathReference) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(deleteDirectory({path: request.path}));
        showSuccessToast("Deleted folder.");
        return success;
    };

    const renameFolder = async ({renameRef}: RenameArgs) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(renameDirectory(renameRef));

        showSuccessToast("Renamed folder.");

        return success;
    };

    /** Deletes a file and will close the modal upon completion */
    const deleteFileRequest = async (request: PathReference) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(deleteFile({path: request.path}));
        showSuccessToast("Deleted file.");
        return success;
    };

    const renameFileRequest = async ({renameRef}: RenameArgs) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(renameFile(renameRef, breadcrumb));
        showSuccessToast("Renamed file.");
        return success;
    };

    return (
        <React.Fragment>
            {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                <ContextMenuTrigger id={iconId}>
                    <div className="icon-wrapper">
                        <div onClick={enterDirectory}>{renderFileIcon(props.fileExtension)}</div>
                        <p className="table-ellipsis mt-1">{getItemName(directoryItem.item)}</p>
                    </div>
                </ContextMenuTrigger>
            }
            {renderMenuOptions(directoryItem.item)}
        </React.Fragment>
    );
};

export default FileIcon;

interface FileIconProps {
    fileExtension: string;
}
