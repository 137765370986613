import {
    StoreServiceData,
    ServiceActionTypes,
    createReducer,
    createDefaultStoreState
} from "store-fetch-wrappers/dist";
import {DELETE_DIRECTORY_STORE_STATE} from "../actions/DeleteDirectoryActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<string>(null);

const deleteDirectoryReducer = (
    state: StoreServiceData<string> = defaultState,
    action: ServiceActionTypes<string>
): StoreServiceData<string> =>
    createReducer(state, action, DELETE_DIRECTORY_STORE_STATE, () => showErrorToast(action.error));

export default deleteDirectoryReducer;
