import {XFile} from "../../../api/xdrive";

export interface UploadManagerFileItem extends XFile {
    file: File;
    uploadState: UploadItemState;
    percentage: number;
}

// eslint-disable-next-line no-shadow
export enum UploadItemState {
    Incomplete = "Incomplete",
    Uploading = "Uploading",
    Complete = "Complete",
    Failed = "Failed"
}
