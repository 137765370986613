import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const DELETE_FILE_STORE_STATE = createStoreState("delete_file");

interface DeleteFileLoading extends StoreServiceData<string> {
    type: typeof DELETE_FILE_STORE_STATE.LOADING;
}
interface DeleteFileError extends StoreServiceData<string> {
    type: typeof DELETE_FILE_STORE_STATE.ERROR;
}
interface DeleteFileSuccess extends StoreServiceData<string> {
    type: typeof DELETE_FILE_STORE_STATE.SUCCESS;
}

export type DeleteFileDispatchTypes = DeleteFileLoading | DeleteFileError | DeleteFileSuccess;
