export const routeNames = {
    xDrive: {
        path: "/x-drive",
        name: "X-Drive"
    },
    uploadFile: {
        path: "/upload",
        name: "Upload File"
    },
    //Auth pages
    login: {
        path: "/login",
        name: "",
        icon: ""
    },
    loggedin: {
        path: "/loggedin",
        name: "",
        icon: ""
    },
    logout: {
        path: "/logout",
        name: "",
        icon: ""
    },
    inActive: {
        path: "/inactive",
        name: "",
        icon: ""
    },
    notAuthorised: {
        path: "/not-authorised",
        name: "",
        icon: ""
    },
    notFound: {
        path: "/not-found",
        name: "",
        icon: ""
    }
};
