import React from "react";
import {useSelector} from "react-redux";
import {RootStore} from "../../../store/Store";

const UploadManagerButton = ({onClick}: UploadManagerButtonProps) => {
    const uploadStore = useSelector((state: RootStore) => state.uploadManager.data || []);

    return (
        <div className="position-relative" onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 100 100"
                height="24"
                width="24"
                className="interactive"
            >
                <path
                    fill="currentColor"
                    d="M20.769,40.723c0.188,0.401,0.591,0.654,1.023,
                        0.654h17.593v14.51c0,5.604,4.538,8.285,10.141,
                        8.285 c5.599,0,10.141-2.682,
                        10.141-8.285v-14.51h17.588c0.441,0,0.836-0.253,
                        1.028-0.654c0.178-0.394,0.117-0.866-0.165-1.199
                        L50.379,6.75c-0.211-0.249-0.525-0.392-0.854-0.392c-0.333,
                        0-0.647,0.143-0.863,0.392L20.938,39.523 C20.651,39.857,
                        20.591,40.329,20.769,40.723z"
                />
                <path
                    fill="currentColor"
                    d="M89.154,25.596c-0.802-2.844-1.989-4.435-4.434-4.435H66.902l3.735,
                        4.409h14.486l7.494,41.255L66.227,66.83 v10.192H33.439V66.837L6.433,
                        66.84l9.756-41.27h12.224l3.731-4.409H14.335c-2.45,0-3.89,
                        1.718-4.435,4.435L0,68.066V94.46  C0,97.522,2.481,
                        100,5.537,100h87.978c3.06,0,5.542-2.478,5.542-5.54V68.066L89.154,
                        25.596z"
                />
            </svg>
            <div className="position-absolute">
                <div className="number-container interactive">
                    <p className="d-flex justify-content-center interactive">
                        {uploadStore.length}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default UploadManagerButton;

interface UploadManagerButtonProps {
    onClick: () => void;
}
