import React, {useEffect} from "react";
import {routeNames} from "../../Navigation/routeNames";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {useDispatch} from "react-redux";
import UploadFileFormContainer from "./Components/UploadFileFormContainer";

const UploadFile = () => {
    const dispatch = useDispatch();

    /** Page Setup */
    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.xDrive.name,
                path: routeNames.xDrive.path
            }
        ];

        //Set Nav Items
        dispatch(setNavigationItems(navItems));
    }, []);
    return (
        <React.Fragment>
            <div className="page-container mt-4">
                <UploadFileFormContainer />
            </div>
        </React.Fragment>
    );
};

export default UploadFile;
