import {Dispatch} from "redux";
import {
    RAW_DIRECTORY_LIST_STORE_STATE,
    RawDirectoryListDispatchTypes
} from "./RawDirectoryListActionTypes";
import {RootStore} from "../../Store";
import XDriveApiModel from "../../apiModel/XDriveApiModel";
import {filterDirectoriesInTree} from "../../../components/Pages/XDrive/Helpers/directoryFilterHelpers";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Nullifies the store so that stale data isn't contained within it. */
export const nullifyRawDirectoryListStore =
    () => async (dispatch: Dispatch<RawDirectoryListDispatchTypes>) => {
        dispatch({
            type: RAW_DIRECTORY_LIST_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };

/** Gets all directories and files within a directory */
export const getDirectoriesForLocation = (path: string) => {
    return async (dispatch: Dispatch<RawDirectoryListDispatchTypes>, store: () => RootStore) => {
        try {
            //Check to see if the path reference is linked, if so, we want to use it, otherwise, set it to undefined.

            await postDataToServiceWithRedux(
                RAW_DIRECTORY_LIST_STORE_STATE,
                dispatch,
                () => XDriveApiModel.getXDriveApi().listFilesInDirectory({path}),
                statusCodeCallback
            );

            const files = store().rawDirectoryList.data;
            return filterDirectoriesInTree(path, files ? files : []);
        } catch (e: any) {
            dispatch({
                type: RAW_DIRECTORY_LIST_STORE_STATE.ERROR,
                loading: false,
                error: e
            });
        }
    };
};
