import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const BREADCRUMB_STORE_STATE = createStoreState("breadcrumb");

interface BreadcrumbLoading extends StoreServiceData<BreadcrumbItem[]> {
    type: typeof BREADCRUMB_STORE_STATE.LOADING;
}
interface BreadcrumbError extends StoreServiceData<BreadcrumbItem[]> {
    type: typeof BREADCRUMB_STORE_STATE.ERROR;
}
interface BreadcrumbSuccess extends StoreServiceData<BreadcrumbItem[]> {
    type: typeof BREADCRUMB_STORE_STATE.SUCCESS;
}

export type BreadcrumbDispatchTypes = BreadcrumbSuccess | BreadcrumbError | BreadcrumbLoading;

export interface BreadcrumbItem {
    relativePath: string;
    uiFriendlyName: string;
    breadcrumbId: string;
    absolutePath: string;
}
