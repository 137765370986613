import {Dispatch} from "redux";
import {
    UPLOAD_MANAGER_NOTIFICATIONS_STORE_STATE,
    UploadManagerNotificationMessage,
    UploadManagerNotificationsDispatchTypes
} from "./UploadManagerNotificationsActionTypes";

/** Nukes store of all uploads and states. */
export const nullifyUploadManagerNotificationStore =
    () => async (dispatch: Dispatch<UploadManagerNotificationsDispatchTypes>) => {
        dispatch({
            type: UPLOAD_MANAGER_NOTIFICATIONS_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };

/** Sets the notification for the store to show */
export const setUploadManagerNotificationMessage =
    (notification: UploadManagerNotificationMessage) =>
    async (dispatch: Dispatch<UploadManagerNotificationsDispatchTypes>) => {
        dispatch({
            type: UPLOAD_MANAGER_NOTIFICATIONS_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: notification
        });
    };
