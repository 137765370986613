import React, {useContext, useState} from "react";
import {MenuItem} from "react-contextmenu";
import {Modal, useModal} from "pulse-modal";
import MCButton, {ButtonColourOptions, ButtonSize} from "../Button/MCButton";
import {Loading} from "store-fetch-wrappers";
import {DebounceInput} from "react-debounce-input";
import DirectoryItemContext from "../Context/DirectoryItemContext";
import {
    getFileExtensionFromPath,
    getItemName
} from "../Pages/XDrive/Helpers/directorySortingHelpers";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../store/Store";
import {validateName} from "../Pages/XDrive/Components/CreateDirectoryForm";
import {DirectoryListItemProps} from "../Pages/XDrive/Components/DirectoryListItem";
import {RenameReference} from "../../api/xdrive";
import {isDirectory} from "../Pages/XDrive/Helpers/directoryFilterHelpers";
import {useBreadcrumb} from "../Hooks/useBreadcrumb";
import {getDirectoriesForLocation} from "../../store/rawDirectoryList/actions/RawDirectoryListActions";

const ContextMenuItemWithRenameAction = ({
    menuItemName,
    modalTitle,
    onConfirm,
    loading,
    renameType
}: ContextMenuItemWithRenameActionProps) => {
    const directoryItem = useContext(DirectoryItemContext);
    const {toggle, isShown} = useModal();
    const [itemName, setItemName] = useState<string>("");
    const directoryStore = useSelector((state: RootStore) => state.rawDirectoryList.data || []);
    const breadcrumb = useBreadcrumb();
    const dispatch = useDispatch();

    const saveRenameRequest = async () => {
        // Don't allow for the item to be saved if the folder name isn't valid
        const valid = validateName(itemName, directoryStore);
        if (!valid) return;

        const success = await onConfirm(getNewRenameReference(directoryItem, breadcrumb, itemName));
        if (!success) {
            return;
        }

        dispatch(getDirectoriesForLocation(breadcrumb));
        onModalClose();
    };

    const onModalClose = () => {
        setItemName("");
        toggle();
    };

    return (
        <React.Fragment>
            {
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                <MenuItem
                    onClick={() => {
                        setItemName(getItemName(directoryItem.item));
                        toggle();
                    }}
                >
                    <span>{menuItemName}</span>
                </MenuItem>
            }

            <Modal
                modalSize={"sm"}
                title={modalTitle}
                bodyChildren={
                    <React.Fragment>
                        <div className="row ml-0 mr-0">
                            <p className="mb-0">{renameType} Name</p>
                        </div>
                        <div className="row ml-0 mr-0">
                            <DebounceInput
                                placeholder={`Enter ${renameType} name...`}
                                className="input-fields"
                                value={itemName}
                                onChange={(e) => setItemName(e.target.value)}
                            />
                        </div>
                        <div className="row ml-0 mr-0">
                            <div className="col text-center">
                                {loading && <Loading type={"three-ring"} showLoadingText={false} />}
                            </div>
                        </div>
                    </React.Fragment>
                }
                onClose={onModalClose}
                isShown={isShown}
                footerChildren={
                    <div className="row ml-0 mr-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Save"}
                            onClick={async () => {
                                await saveRenameRequest();
                            }}
                            colour={ButtonColourOptions.Yellow}
                            roundedCorner
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Cancel"}
                            onClick={onModalClose}
                            colour={ButtonColourOptions.DarkBlue}
                            roundedCorner
                        />
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default ContextMenuItemWithRenameAction;

interface ContextMenuItemWithRenameActionProps {
    menuItemName: string;
    onConfirm: (renameArgs: RenameArgs) => Promise<boolean>;
    modalTitle: string;
    loading: boolean;
    renameType: "Folder" | "File";
}

export interface RenameArgs {
    renameRef: RenameReference;
    breadcrumb: string;
}

function getNewRenameReference(
    directoryItem: DirectoryListItemProps,
    breadcrumb: string,
    newItemName: string
): RenameArgs {
    //Folder
    if (isDirectory(directoryItem.item)) {
        return {
            renameRef: {
                oldPath: directoryItem.item,
                newPath: `${breadcrumb}${newItemName}`
            },
            breadcrumb
        };
    }
    //File
    return {
        renameRef: {
            oldPath: directoryItem.item,
            newPath: `${breadcrumb}${newItemName}${getFileExtensionFromPath(directoryItem.item)}`
        },
        breadcrumb
    };
}
