import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {SAVE_FILE_STORE_STATE} from "../actions/SaveFileActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<string>(null);

const saveFileReducer = (
    state: StoreServiceData<string> = defaultState,
    action: ServiceActionTypes<string>
): StoreServiceData<string> =>
    createReducer(state, action, SAVE_FILE_STORE_STATE, () => showErrorToast(action.error));

export default saveFileReducer;
