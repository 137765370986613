import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootStore} from "../../store/Store";
import {nullifyUploadManagerNotificationStore} from "../../store/uploadManagerNotifications/actions/UploadManagerNotificationsActions";
import XDriveTooltip, {TooltipDirection} from "./XDriveTooltip";

/** Control and listens to anything pushed to the store in terms of notifications */
const UploadManagerNotifications = ({children}: UploadManagerNotificationsProps) => {
    const autoLoginTimeout: any | undefined = undefined;
    const timer = useRef(autoLoginTimeout);
    const [message, setMessage] = useState<string>("");
    const uploadManagerNotificationStore = useSelector(
        (state: RootStore) => state.uploadManagerNotifications
    );
    const dispatch = useDispatch();

    //Anytime in the store changes, we reset the timer on the notification, and manage the notification being displayed.
    useEffect(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        const incomingMessage = uploadManagerNotificationStore.data;
        if (!incomingMessage) {
            setMessage("");
            return;
        }

        if (message !== incomingMessage.message) {
            setMessage("");
        }

        setMessage(incomingMessage.message);

        timer.current = setTimeout(() => {
            dispatch(nullifyUploadManagerNotificationStore());
        }, 5000);
    }, [uploadManagerNotificationStore.data]);

    return (
        <React.Fragment>
            <XDriveTooltip
                direction={TooltipDirection.TOP_LEFT}
                content={message && message.length > 0 ? message : undefined}
            >
                <div>{children}</div>
            </XDriveTooltip>
        </React.Fragment>
    );
};

export default UploadManagerNotifications;

interface UploadManagerNotificationsProps {
    children: JSX.Element;
}
