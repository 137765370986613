import React, {useEffect, useState} from "react";
import {getFileExtensionFromPath, getItemName} from "../../XDrive/Helpers/directorySortingHelpers";
import {useQuery} from "../../../Hooks/useQuery";
import {useDispatch} from "react-redux";
import {setFileInListStore} from "../../../../store/uploadedFileList/actions/UploadedFileListActions";
import RemoveFileAction from "./RemoveFileAction";
import {UploadedFile} from "../../../../store/uploadedFileList/actions/UploadedFileListActionTypes";
import {DebounceInput} from "react-debounce-input";

const UploadFileForm = (props: UploadFileFormProps) => {
    const [directoryLocation, setDirectoryLocation] = useState("");
    const query = useQuery();
    const dispatch = useDispatch();

    /** Mounted, get the query string for target folder location of file. If not, default it to the root */
    useEffect(() => {
        //Query strings
        const dirLoc = query.get("dirLoc");
        setDirectoryLocation(dirLoc ? dirLoc : "");
    }, []);

    /** Updates Item Name */
    const updateFileName = (event: React.ChangeEvent<HTMLInputElement>) => {
        const ext = getFileExtensionFromPath(props.file.name);
        const name = event.target.value.length > 0 ? `${event.target.value}${ext}` : "";
        const file: UploadedFile = {
            file: props.file,
            name
        };

        dispatch(setFileInListStore(file, props.index));
    };

    return (
        <React.Fragment>
            <div className="row ml-0 mr-0 file-form-header">
                <div className="col">
                    <p className="mb-0 mt-1">
                        <b className="header-font">Upload {props.index + 1}:</b>
                        <RemoveFileAction {...props} />
                    </p>
                </div>
            </div>
            <div className="section-borders mb-5 pl-3 pr-3 pt-3 pb-3">
                <div className="row ml-0 mr-0">
                    <div className="col pl-0 pr-0">
                        <p className="mb-2">
                            <b>File Name</b>
                        </p>
                    </div>
                </div>
                <div className="row ml-0 mr-0">
                    <div className="col pl-0 pr-0">
                        <div className="input-group suffix">
                            <DebounceInput
                                value={getItemName(props.name)}
                                onChange={updateFileName}
                                className="input-fields"
                                placeholder={`Enter new file name`}
                            />
                            <span className="input-group-addon">
                                <b>{getFileExtensionFromPath(props.file.name)}</b>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="row ml-0 mr-0 mt-3">
                    <div className="col pl-0 pr-0">
                        <p className="mb-2">
                            <b>Save Path:</b>
                        </p>
                    </div>
                </div>
                <div className="row ml-0 mr-0">
                    <div className="col pl-0 pr-0">
                        <p className="mb-2">{getItemName(directoryLocation)}</p>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default UploadFileForm;

export interface UploadFileFormProps {
    file: File;
    name: string;
    index: number;
}
