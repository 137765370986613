import {Dispatch} from "redux";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import XDriveApiModel from "../../apiModel/XDriveApiModel";
import {RenameReference} from "../../../api/xdrive";
import {
    RENAME_DIRECTORY_STORE_STATE,
    RenameDirectoryDispatchTypes
} from "./RenameDirectoryActionTypes";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Clears store of stale data */
export const nullifyRenameDirectoryStore =
    () => async (dispatch: Dispatch<RenameDirectoryDispatchTypes>) => {
        dispatch({
            type: RENAME_DIRECTORY_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };

/** Calls to the service to allow for a directory to be renamed. */
export const renameDirectory =
    (args: RenameReference) => async (dispatch: Dispatch<RenameDirectoryDispatchTypes>) => {
        try {
            const newArgs: RenameReference = {
                ...args,
                newPath: args.newPath.endsWith("/") ? args.newPath : `${args.newPath}/`
            };
            return await postDataToServiceWithRedux(
                RENAME_DIRECTORY_STORE_STATE,
                dispatch,
                () => XDriveApiModel.getXDriveApi().renameDirectory(newArgs),
                statusCodeCallback
            );
        } catch (e: any) {
            dispatch({
                type: RENAME_DIRECTORY_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
        }
    };
