import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const RENAME_FILE_STORE_STATE = createStoreState("rename_file");

interface RenameFileLoading extends StoreServiceData<string> {
    type: typeof RENAME_FILE_STORE_STATE.LOADING;
}
interface RenameFileError extends StoreServiceData<string> {
    type: typeof RENAME_FILE_STORE_STATE.ERROR;
}
interface RenameFileSuccess extends StoreServiceData<string> {
    type: typeof RENAME_FILE_STORE_STATE.SUCCESS;
}

export type RenameFileDispatchTypes = RenameFileError | RenameFileLoading | RenameFileSuccess;
