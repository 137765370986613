import React from "react";
import {useDispatch} from "react-redux";
import {UploadFileFormProps} from "./UploadFileForm";
import {removeFileInListStore} from "../../../../store/uploadedFileList/actions/UploadedFileListActions";
import {Modal, useModal} from "pulse-modal";
import Icon, {IconType} from "../../../Icon/Icon";
import Tooltip from "../../../Tooltip/Tooltip";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";

const RemoveFileAction = (props: UploadFileFormProps) => {
    const dispatch = useDispatch();
    const {toggle, isShown} = useModal();

    const removeFromUploadQueue = () => {
        dispatch(removeFileInListStore(props.index));
        toggle();
    };
    return (
        <React.Fragment>
            <Tooltip
                tooltipText={"Remove from upload list"}
                size={"sm"}
                place={"right"}
                rootElement={"span"}
                wrapperClassName={"interactive"}
            >
                <Icon
                    rootElement={"Span"}
                    icon={IconType.Cross}
                    size={"Small"}
                    onClick={toggle}
                    className={"ml-3"}
                />
            </Tooltip>
            <Modal
                isShown={isShown}
                onClose={toggle}
                title={"Remove upload"}
                modalSize={"sm"}
                bodyChildren={
                    <div className="row ml-0 mr-0">
                        <p>Are you sure you want to delete this item from the upload queue?</p>
                    </div>
                }
                footerChildren={
                    <div className="row ml-0 mr-0">
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"Yes"}
                            onClick={removeFromUploadQueue}
                            colour={ButtonColourOptions.Yellow}
                        />
                        <MCButton
                            size={ButtonSize.Large}
                            innerValue={"No"}
                            onClick={toggle}
                            colour={ButtonColourOptions.DarkBlue}
                        />
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default RemoveFileAction;
