import React, {useEffect, useState} from "react";

/** Tooltip used to control notifications being shown */
const XDriveTooltip = ({direction, children, content, delay}: TooltipProps) => {
    let timeout: any;
    const [active, setActive] = useState(false);

    // Shows tooltip
    const showTip = () => {
        timeout = setTimeout(() => {
            setActive(true);
        }, delay || 400);
    };

    // Hides tooltip
    const hideTip = () => {
        clearInterval(timeout);
        setActive(false);
    };

    //Watches the content of the tooltip. Acts accordingly to show/hide tip
    useEffect(() => {
        if (!content) {
            hideTip();
            return;
        }

        if (content.length === 0 || content === "") {
            hideTip();
        } else {
            showTip();
        }
    }, [content]);

    return (
        <div className="Tooltip-Wrapper">
            {/* Wrapping */}
            {children}
            {active && (
                <div className={`Tooltip-Tip ${direction || "top"}`}>
                    {/* Content */}
                    {content}
                </div>
            )}
        </div>
    );
};

export default XDriveTooltip;

export interface TooltipProps {
    direction: TooltipDirection;
    children: JSX.Element;
    content?: string;
    delay?: number;
}

// eslint-disable-next-line no-shadow
export enum TooltipDirection {
    LEFT = "left",
    TOP_LEFT = "top-left",
    RIGHT = "right",
    TOP = "top",
    BOTTOM = "bottom"
}
