import React from "react";
import {useSelector} from "react-redux";
import {RootStore} from "../../store/Store";

import {WithServiceState} from "store-fetch-wrappers";
import UploadManagerContainer from "./Components/UploadManagerContainer";
import UploadManagerButton from "./Components/UploadManagerButton";
import UploadManagerNotifications from "../UploadManagerNotifications/UploadManagerNotifications";
import {Modal, useModal} from "pulse-modal";

const ServiceWrapper = WithServiceState(UploadManagerContainer);

/** Upload Manager which watches and shows the items in the queue */
const UploadManager = () => {
    const uploadStore = useSelector((state: RootStore) => state.uploadManager);

    const {isShown, toggle} = useModal();

    return (
        <React.Fragment>
            <UploadManagerNotifications>
                <UploadManagerButton onClick={toggle} />
            </UploadManagerNotifications>
            <Modal
                modalSize={"lg"}
                title={"Upload Manager"}
                bodyChildren={
                    <ServiceWrapper
                        loaderWheelType={"three-ring"}
                        loaderType={"normal"}
                        showLoadingText={false}
                        {...uploadStore}
                    />
                }
                onClose={toggle}
                isShown={isShown}
            />
        </React.Fragment>
    );
};

export default UploadManager;
