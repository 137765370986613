// tslint:disable
/// <reference path="./custom.d.ts" />
/**
 * Medicare X-Drive
 * ## API for Medicare X-Drive.  ### Paths must follow the following format:  **Directories** must end with a / **Files** must **not** end with a / **No path** must start with /, it is implicit  Paths include the path and filename: myfolder/subfolder/file.txt  ### General Notes  Directories don't physically exist and are created based on the paths of files. When renaming a file or folder, the parents directories will be 'created' automatically as required. Renaming a file to a file that already exists will overwrite it. Renaming a folder to one that already exists will put the files into the existing folder, overwriting any existing files with the same name. 
 *
 * OpenAPI spec version: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as url from "url";
import { Configuration } from "./configuration";
import globalAxios, { AxiosPromise, AxiosInstance } from 'axios';

const BASE_PATH = "http://localhost:9005/v1".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *  
 * @export
 * @interface RequestArgs
 */
export interface RequestArgs {
    url: string;
    options: any;
}

/**
 * 
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration | undefined;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected axios: AxiosInstance = globalAxios) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 * 
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError" = "RequiredError";
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * Cancel a multi-part upload
 * @export
 * @interface CancelMultiPartRequest
 */
export interface CancelMultiPartRequest {
    /**
     * Path to the file
     * @type {string}
     * @memberof CancelMultiPartRequest
     */
    path: string;
    /**
     * Unique ID for this upload
     * @type {string}
     * @memberof CancelMultiPartRequest
     */
    uploadId: string;
}

/**
 * Request to complete a multi-part upload
 * @export
 * @interface CompleteMultiPartRequest
 */
export interface CompleteMultiPartRequest {
    /**
     * Path to the file
     * @type {string}
     * @memberof CompleteMultiPartRequest
     */
    path: string;
    /**
     * Unique ID for this upload
     * @type {string}
     * @memberof CompleteMultiPartRequest
     */
    uploadId: string;
    /**
     * The uploaded parts that should be used to complete the upload
     * @type {Array<MultiPartUploadPart>}
     * @memberof CompleteMultiPartRequest
     */
    parts: Array<MultiPartUploadPart>;
}

/**
 * An error has occurred, and this object contains the detailed information
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
    /**
     * 
     * @type {string}
     * @memberof ErrorResponse
     */
    message: string;
}

/**
 * A part within a multi-part upload
 * @export
 * @interface MultiPartUploadPart
 */
export interface MultiPartUploadPart {
    /**
     * Part number between 1 and 10,000. When assembled parts will be in numeric order.
     * @type {string}
     * @memberof MultiPartUploadPart
     */
    partNumber: string;
    /**
     * Unique identifier for this part upload
     * @type {string}
     * @memberof MultiPartUploadPart
     */
    etag: string;
}

/**
 * Response containing information required to complete a multi-part upload
 * @export
 * @interface MultiPartUploadStartResponse
 */
export interface MultiPartUploadStartResponse {
    /**
     * Upload ID to reference when referring to this multi-part upload
     * @type {string}
     * @memberof MultiPartUploadStartResponse
     */
    uploadId: string;
}

/**
 * Reference to a file or folder
 * @export
 * @interface PathReference
 */
export interface PathReference {
    /**
     * Path to the file or folder
     * @type {string}
     * @memberof PathReference
     */
    path: string;
}

/**
 * Paths to files or folders to rename
 * @export
 * @interface RenameReference
 */
export interface RenameReference {
    /**
     * Original path to file or folder
     * @type {string}
     * @memberof RenameReference
     */
    oldPath: string;
    /**
     * New path for file or folder
     * @type {string}
     * @memberof RenameReference
     */
    newPath: string;
}

/**
 * A file in X-Drive
 * @export
 * @interface XFile
 */
export interface XFile {
    /**
     * Full path for the file
     * @type {string}
     * @memberof XFile
     */
    path: string;
    /**
     * MIME type of the file
     * @type {string}
     * @memberof XFile
     */
    contentType: string;
    /**
     * Any additional meta you would like to store. Valid characters are:   * A-Z   * a-z   * 0-9   * -   * _   * [space]  The key will always be converted to lowercase. 
     * @type {{ [key: string]: string; }}
     * @memberof XFile
     */
    meta: { [key: string]: string; };
}

/**
 * File with the file contents as well
 * @export
 * @interface XFileWithData
 */
export interface XFileWithData {
    /**
     * 
     * @type {XFile}
     * @memberof XFileWithData
     */
    file: XFile;
    /**
     * Base64 encoded file contents
     * @type {string}
     * @memberof XFileWithData
     */
    data: string;
}


/**
 * DefaultApi - axios parameter creator
 * @export
 */
export const DefaultApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel uploading of a multipart file, clear all uploaded parts
         * @param {CancelMultiPartRequest} [cancelMultiPartRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelMultipartFile(cancelMultiPartRequest?: CancelMultiPartRequest, options: any = {}): RequestArgs {
            const localVarPath = `/file/multipart/cancel`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CancelMultiPartRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(cancelMultiPartRequest || {}) : (cancelMultiPartRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Complete the multi-part file. Call after uploading all parts.
         * @param {CompleteMultiPartRequest} [completeMultiPartRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeMultipartFile(completeMultiPartRequest?: CompleteMultiPartRequest, options: any = {}): RequestArgs {
            const localVarPath = `/file/multipart/complete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"CompleteMultiPartRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(completeMultiPartRequest || {}) : (completeMultiPartRequest || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Create a directory
         * @param {PathReference} [pathReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectory(pathReference?: PathReference, options: any = {}): RequestArgs {
            const localVarPath = `/directory/create`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PathReference" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(pathReference || {}) : (pathReference || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a directory
         * @param {PathReference} [pathReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDirectory(pathReference?: PathReference, options: any = {}): RequestArgs {
            const localVarPath = `/directory/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PathReference" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(pathReference || {}) : (pathReference || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete a file
         * @param {PathReference} [pathReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(pathReference?: PathReference, options: any = {}): RequestArgs {
            const localVarPath = `/file/delete`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PathReference" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(pathReference || {}) : (pathReference || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get a public time-limited link direct to the file
         * @param {PathReference} [pathReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileLink(pathReference?: PathReference, options: any = {}): RequestArgs {
            const localVarPath = `/file/link`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PathReference" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(pathReference || {}) : (pathReference || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get all folders (end with /) and files within a directory
         * @param {PathReference} [pathReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFilesInDirectory(pathReference?: PathReference, options: any = {}): RequestArgs {
            const localVarPath = `/directory/list`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"PathReference" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(pathReference || {}) : (pathReference || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rename a directory. **WARNING** Will overwrite existing files in destination (including nested) if they exist in the source and destination
         * @param {RenameReference} [renameReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameDirectory(renameReference?: RenameReference, options: any = {}): RequestArgs {
            const localVarPath = `/directory/rename`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RenameReference" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(renameReference || {}) : (renameReference || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Rename a file. **WARNING** Will overwrite existing file if any exists
         * @param {RenameReference} [renameReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameFile(renameReference?: RenameReference, options: any = {}): RequestArgs {
            const localVarPath = `/file/rename`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"RenameReference" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(renameReference || {}) : (renameReference || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Save a file, overwriting existing
         * @param {XFileWithData} [xFileWithData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFile(xFileWithData?: XFileWithData, options: any = {}): RequestArgs {
            const localVarPath = `/file`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"XFileWithData" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(xFileWithData || {}) : (xFileWithData || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Start a multi-part upload process. Parts must be between 5MB and 50MB, except for the final part. Maximum number of parts is 10,000.
         * @param {XFile} [xFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMultipartFile(xFile?: XFile, options: any = {}): RequestArgs {
            const localVarPath = `/file/multipart/start`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"XFile" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? JSON.stringify(xFile || {}) : (xFile || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Save a single part of the multipart file. Parts can be re-uploaded, replacing the already uploaded part. Once the file is complete, to replace a part the whole file must be re-uploaded.
         * @summary Save a single part of the multipart file
         * @param {string} X_UPLOAD_ID Unique upload ID returned in start request
         * @param {string} X_PART_NUMBER Part number 1-10,000
         * @param {string} X_PATH Path to file
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMultipartFilePart(X_UPLOAD_ID: string, X_PART_NUMBER: string, X_PATH: string, body?: any, options: any = {}): RequestArgs {
            // verify required parameter 'X_UPLOAD_ID' is not null or undefined
            if (X_UPLOAD_ID === null || X_UPLOAD_ID === undefined) {
                throw new RequiredError('X_UPLOAD_ID','Required parameter X_UPLOAD_ID was null or undefined when calling saveMultipartFilePart.');
            }
            // verify required parameter 'X_PART_NUMBER' is not null or undefined
            if (X_PART_NUMBER === null || X_PART_NUMBER === undefined) {
                throw new RequiredError('X_PART_NUMBER','Required parameter X_PART_NUMBER was null or undefined when calling saveMultipartFilePart.');
            }
            // verify required parameter 'X_PATH' is not null or undefined
            if (X_PATH === null || X_PATH === undefined) {
                throw new RequiredError('X_PATH','Required parameter X_PATH was null or undefined when calling saveMultipartFilePart.');
            }
            const localVarPath = `/file/multipart/part`;
            const localVarUrlObj = url.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign({ method: 'POST' }, baseOptions, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (X_UPLOAD_ID !== undefined && X_UPLOAD_ID !== null) {
                localVarHeaderParameter['x-upload_id'] = String(X_UPLOAD_ID);
            }

            if (X_PART_NUMBER !== undefined && X_PART_NUMBER !== null) {
                localVarHeaderParameter['x-part_number'] = String(X_PART_NUMBER);
            }

            if (X_PATH !== undefined && X_PATH !== null) {
                localVarHeaderParameter['x-path'] = String(X_PATH);
            }

            localVarHeaderParameter['Content-Type'] = 'application/octet-stream';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"any" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.data =  needsSerialization ? body || {} : (body || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DefaultApi - functional programming interface
 * @export
 */
export const DefaultApiFp = function(configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Cancel uploading of a multipart file, clear all uploaded parts
         * @param {CancelMultiPartRequest} [cancelMultiPartRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelMultipartFile(cancelMultiPartRequest?: CancelMultiPartRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).cancelMultipartFile(cancelMultiPartRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Complete the multi-part file. Call after uploading all parts.
         * @param {CompleteMultiPartRequest} [completeMultiPartRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeMultipartFile(completeMultiPartRequest?: CompleteMultiPartRequest, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).completeMultipartFile(completeMultiPartRequest, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Create a directory
         * @param {PathReference} [pathReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectory(pathReference?: PathReference, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).createDirectory(pathReference, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Delete a directory
         * @param {PathReference} [pathReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDirectory(pathReference?: PathReference, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteDirectory(pathReference, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Delete a file
         * @param {PathReference} [pathReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(pathReference?: PathReference, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).deleteFile(pathReference, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get a public time-limited link direct to the file
         * @param {PathReference} [pathReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileLink(pathReference?: PathReference, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<string> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).getFileLink(pathReference, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Get all folders (end with /) and files within a directory
         * @param {PathReference} [pathReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFilesInDirectory(pathReference?: PathReference, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).listFilesInDirectory(pathReference, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Rename a directory. **WARNING** Will overwrite existing files in destination (including nested) if they exist in the source and destination
         * @param {RenameReference} [renameReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameDirectory(renameReference?: RenameReference, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).renameDirectory(renameReference, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Rename a file. **WARNING** Will overwrite existing file if any exists
         * @param {RenameReference} [renameReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameFile(renameReference?: RenameReference, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).renameFile(renameReference, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Save a file, overwriting existing
         * @param {XFileWithData} [xFileWithData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFile(xFileWithData?: XFileWithData, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Response> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).saveFile(xFileWithData, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * 
         * @summary Start a multi-part upload process. Parts must be between 5MB and 50MB, except for the final part. Maximum number of parts is 10,000.
         * @param {XFile} [xFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMultipartFile(xFile?: XFile, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiPartUploadStartResponse> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).saveMultipartFile(xFile, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
        /**
         * Save a single part of the multipart file. Parts can be re-uploaded, replacing the already uploaded part. Once the file is complete, to replace a part the whole file must be re-uploaded.
         * @summary Save a single part of the multipart file
         * @param {string} X_UPLOAD_ID Unique upload ID returned in start request
         * @param {string} X_PART_NUMBER Part number 1-10,000
         * @param {string} X_PATH Path to file
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMultipartFilePart(X_UPLOAD_ID: string, X_PART_NUMBER: string, X_PATH: string, body?: any, options?: any): (axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultiPartUploadPart> {
            const localVarAxiosArgs = DefaultApiAxiosParamCreator(configuration).saveMultipartFilePart(X_UPLOAD_ID, X_PART_NUMBER, X_PATH, body, options);
            return (axios: AxiosInstance = globalAxios, basePath: string = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(localVarAxiosArgs.options, {url: basePath + localVarAxiosArgs.url})
                return axios.request(axiosRequestArgs);                
            };
        },
    }
};

/**
 * DefaultApi - factory interface
 * @export
 */
export const DefaultApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    return {
        /**
         * 
         * @summary Cancel uploading of a multipart file, clear all uploaded parts
         * @param {CancelMultiPartRequest} [cancelMultiPartRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelMultipartFile(cancelMultiPartRequest?: CancelMultiPartRequest, options?: any) {
            return DefaultApiFp(configuration).cancelMultipartFile(cancelMultiPartRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Complete the multi-part file. Call after uploading all parts.
         * @param {CompleteMultiPartRequest} [completeMultiPartRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        completeMultipartFile(completeMultiPartRequest?: CompleteMultiPartRequest, options?: any) {
            return DefaultApiFp(configuration).completeMultipartFile(completeMultiPartRequest, options)(axios, basePath);
        },
        /**
         * 
         * @summary Create a directory
         * @param {PathReference} [pathReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createDirectory(pathReference?: PathReference, options?: any) {
            return DefaultApiFp(configuration).createDirectory(pathReference, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete a directory
         * @param {PathReference} [pathReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteDirectory(pathReference?: PathReference, options?: any) {
            return DefaultApiFp(configuration).deleteDirectory(pathReference, options)(axios, basePath);
        },
        /**
         * 
         * @summary Delete a file
         * @param {PathReference} [pathReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(pathReference?: PathReference, options?: any) {
            return DefaultApiFp(configuration).deleteFile(pathReference, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get a public time-limited link direct to the file
         * @param {PathReference} [pathReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileLink(pathReference?: PathReference, options?: any) {
            return DefaultApiFp(configuration).getFileLink(pathReference, options)(axios, basePath);
        },
        /**
         * 
         * @summary Get all folders (end with /) and files within a directory
         * @param {PathReference} [pathReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listFilesInDirectory(pathReference?: PathReference, options?: any) {
            return DefaultApiFp(configuration).listFilesInDirectory(pathReference, options)(axios, basePath);
        },
        /**
         * 
         * @summary Rename a directory. **WARNING** Will overwrite existing files in destination (including nested) if they exist in the source and destination
         * @param {RenameReference} [renameReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameDirectory(renameReference?: RenameReference, options?: any) {
            return DefaultApiFp(configuration).renameDirectory(renameReference, options)(axios, basePath);
        },
        /**
         * 
         * @summary Rename a file. **WARNING** Will overwrite existing file if any exists
         * @param {RenameReference} [renameReference] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameFile(renameReference?: RenameReference, options?: any) {
            return DefaultApiFp(configuration).renameFile(renameReference, options)(axios, basePath);
        },
        /**
         * 
         * @summary Save a file, overwriting existing
         * @param {XFileWithData} [xFileWithData] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveFile(xFileWithData?: XFileWithData, options?: any) {
            return DefaultApiFp(configuration).saveFile(xFileWithData, options)(axios, basePath);
        },
        /**
         * 
         * @summary Start a multi-part upload process. Parts must be between 5MB and 50MB, except for the final part. Maximum number of parts is 10,000.
         * @param {XFile} [xFile] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMultipartFile(xFile?: XFile, options?: any) {
            return DefaultApiFp(configuration).saveMultipartFile(xFile, options)(axios, basePath);
        },
        /**
         * Save a single part of the multipart file. Parts can be re-uploaded, replacing the already uploaded part. Once the file is complete, to replace a part the whole file must be re-uploaded.
         * @summary Save a single part of the multipart file
         * @param {string} X_UPLOAD_ID Unique upload ID returned in start request
         * @param {string} X_PART_NUMBER Part number 1-10,000
         * @param {string} X_PATH Path to file
         * @param {any} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveMultipartFilePart(X_UPLOAD_ID: string, X_PART_NUMBER: string, X_PATH: string, body?: any, options?: any) {
            return DefaultApiFp(configuration).saveMultipartFilePart(X_UPLOAD_ID, X_PART_NUMBER, X_PATH, body, options)(axios, basePath);
        },
    };
};

/**
 * DefaultApi - object-oriented interface
 * @export
 * @class DefaultApi
 * @extends {BaseAPI}
 */
export class DefaultApi extends BaseAPI {
    /**
     * 
     * @summary Cancel uploading of a multipart file, clear all uploaded parts
     * @param {CancelMultiPartRequest} [cancelMultiPartRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public cancelMultipartFile(cancelMultiPartRequest?: CancelMultiPartRequest, options?: any) {
        return DefaultApiFp(this.configuration).cancelMultipartFile(cancelMultiPartRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Complete the multi-part file. Call after uploading all parts.
     * @param {CompleteMultiPartRequest} [completeMultiPartRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public completeMultipartFile(completeMultiPartRequest?: CompleteMultiPartRequest, options?: any) {
        return DefaultApiFp(this.configuration).completeMultipartFile(completeMultiPartRequest, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Create a directory
     * @param {PathReference} [pathReference] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public createDirectory(pathReference?: PathReference, options?: any) {
        return DefaultApiFp(this.configuration).createDirectory(pathReference, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete a directory
     * @param {PathReference} [pathReference] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteDirectory(pathReference?: PathReference, options?: any) {
        return DefaultApiFp(this.configuration).deleteDirectory(pathReference, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Delete a file
     * @param {PathReference} [pathReference] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public deleteFile(pathReference?: PathReference, options?: any) {
        return DefaultApiFp(this.configuration).deleteFile(pathReference, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get a public time-limited link direct to the file
     * @param {PathReference} [pathReference] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public getFileLink(pathReference?: PathReference, options?: any) {
        return DefaultApiFp(this.configuration).getFileLink(pathReference, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Get all folders (end with /) and files within a directory
     * @param {PathReference} [pathReference] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public listFilesInDirectory(pathReference?: PathReference, options?: any) {
        return DefaultApiFp(this.configuration).listFilesInDirectory(pathReference, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Rename a directory. **WARNING** Will overwrite existing files in destination (including nested) if they exist in the source and destination
     * @param {RenameReference} [renameReference] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public renameDirectory(renameReference?: RenameReference, options?: any) {
        return DefaultApiFp(this.configuration).renameDirectory(renameReference, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Rename a file. **WARNING** Will overwrite existing file if any exists
     * @param {RenameReference} [renameReference] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public renameFile(renameReference?: RenameReference, options?: any) {
        return DefaultApiFp(this.configuration).renameFile(renameReference, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Save a file, overwriting existing
     * @param {XFileWithData} [xFileWithData] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveFile(xFileWithData?: XFileWithData, options?: any) {
        return DefaultApiFp(this.configuration).saveFile(xFileWithData, options)(this.axios, this.basePath);
    }

    /**
     * 
     * @summary Start a multi-part upload process. Parts must be between 5MB and 50MB, except for the final part. Maximum number of parts is 10,000.
     * @param {XFile} [xFile] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveMultipartFile(xFile?: XFile, options?: any) {
        return DefaultApiFp(this.configuration).saveMultipartFile(xFile, options)(this.axios, this.basePath);
    }

    /**
     * Save a single part of the multipart file. Parts can be re-uploaded, replacing the already uploaded part. Once the file is complete, to replace a part the whole file must be re-uploaded.
     * @summary Save a single part of the multipart file
     * @param {string} X_UPLOAD_ID Unique upload ID returned in start request
     * @param {string} X_PART_NUMBER Part number 1-10,000
     * @param {string} X_PATH Path to file
     * @param {any} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DefaultApi
     */
    public saveMultipartFilePart(X_UPLOAD_ID: string, X_PART_NUMBER: string, X_PATH: string, body?: any, options?: any) {
        return DefaultApiFp(this.configuration).saveMultipartFilePart(X_UPLOAD_ID, X_PART_NUMBER, X_PATH, body, options)(this.axios, this.basePath);
    }

}

