import {Dispatch} from "redux";
import XDriveApiModel from "../../apiModel/XDriveApiModel";
import {PathReference} from "../../../api/xdrive";
import {
    CREATE_DIRECTORY_STORE_STATE,
    CreateDirectoryDispatchTypes
} from "./CreateDirectoryActionTypes";
import store from "../../Store";
import {getDirectoriesForLocation} from "../../rawDirectoryList/actions/RawDirectoryListActions";
import {postDataToServiceWithRedux} from "store-fetch-wrappers";
import {statusCodeCallback} from "../../helpers/storeHelpers";

/** Clears Store of stale data */
export const nullifyCreateDirectoryStore =
    () => async (dispatch: Dispatch<CreateDirectoryDispatchTypes>) => {
        dispatch({
            type: CREATE_DIRECTORY_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: null
        });
    };

/** Calls to the service to allow for a directory to be deleted. */
export const createDirectory =
    (path: PathReference, breadcrumb: string) =>
    async (dispatch: Dispatch<CreateDirectoryDispatchTypes>) => {
        try {
            await postDataToServiceWithRedux(
                CREATE_DIRECTORY_STORE_STATE,
                dispatch,
                () => XDriveApiModel.getXDriveApi().createDirectory(path),
                statusCodeCallback
            );
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(getDirectoriesForLocation(breadcrumb));

            return true;
        } catch (e: any) {
            dispatch({
                type: CREATE_DIRECTORY_STORE_STATE.ERROR,
                error: e,
                loading: false
            });
            return false;
        }
    };
