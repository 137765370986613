import {Dispatch} from "redux";
import {
    BREADCRUMB_STORE_STATE,
    BreadcrumbDispatchTypes,
    BreadcrumbItem
} from "./BreadcrumbActionTypes";
import store from "../../Store";
import {getItemName} from "../../../components/Pages/XDrive/Helpers/directorySortingHelpers";

/** Nukes the store. Ensures there is no stale data left */
export const nullifyBreadcrumbStore = () => async (dispatch: Dispatch<BreadcrumbDispatchTypes>) => {
    dispatch({
        type: BREADCRUMB_STORE_STATE.SUCCESS,
        loading: false,
        error: null,
        data: null
    });
};

/** Pushes item to breadcrumb. Once pushed, watcher will refresh the request to get the new directory list based on breadcrumb */
export const pushToBreadCrumb =
    (itemToPush: BreadcrumbItem) => async (dispatch: Dispatch<BreadcrumbDispatchTypes>) => {
        const currentBreadcrumb = store.getState().breadcrumb.data;
        if (!currentBreadcrumb) return;

        dispatch({
            type: BREADCRUMB_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: [...currentBreadcrumb, itemToPush]
        });
    };

/** For when the user clicks on a specific part of the bread crumb. It create a new bread crumb based on the index of the breadcrumb clicked.*/
export const goToBreadCrumb =
    (item: BreadcrumbItem) => async (dispatch: Dispatch<BreadcrumbDispatchTypes>) => {
        const currentBreadcrumb = store.getState().breadcrumb.data;

        if (!currentBreadcrumb) return;
        const index = currentBreadcrumb.findIndex(
            (el: BreadcrumbItem) => el.breadcrumbId === item.breadcrumbId
        );

        const newBreadcrumb =
            index > -1
                ? currentBreadcrumb.slice(0, index + 1)
                : [
                      {
                          relativePath: "",
                          uiFriendlyName: "Home",
                          breadcrumbId: "Home",
                          absolutePath: ""
                      }
                  ];

        dispatch({
            type: BREADCRUMB_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: newBreadcrumb
        });
    };

/** Goes to home breadcrumb */
export const goToHomeBreadCrumb = () => async (dispatch: Dispatch<BreadcrumbDispatchTypes>) => {
    dispatch({
        type: BREADCRUMB_STORE_STATE.SUCCESS,
        error: null,
        loading: false,
        data: [
            {
                relativePath: "",
                uiFriendlyName: "Home",
                breadcrumbId: "Home",
                absolutePath: ""
            }
        ]
    });
};
/** builds breadcrumb from absolute path and will set the store. */
export const goToBreadcrumbByAbsolutePath =
    (path: string) => async (dispatch: Dispatch<BreadcrumbDispatchTypes>) => {
        const crumbs = buildBreadcrumbsFromAbsolutePath(path);

        dispatch({
            type: BREADCRUMB_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: crumbs
        });
    };

/** For when the user goes up one level in the directory */
export const removeEndCrumb = () => async (dispatch: Dispatch<BreadcrumbDispatchTypes>) => {
    const currentBreadcrumb = store.getState().breadcrumb.data;

    if (!currentBreadcrumb) return;

    currentBreadcrumb.splice(currentBreadcrumb.length - 1, 1);

    dispatch({
        type: BREADCRUMB_STORE_STATE.SUCCESS,
        error: null,
        loading: false,
        data: currentBreadcrumb
    });
};

/** Build breadcrumbs from absolute path */
export function buildBreadcrumbsFromAbsolutePath(path: string): Array<BreadcrumbItem> {
    //Separate by / and remove any empty strings
    const crumbs: string[] = path.split("/").filter((item: string) => item.length > 0);

    //Set default state.
    const breadcrumbs: BreadcrumbItem[] = [
        {
            relativePath: "",
            uiFriendlyName: "Home",
            breadcrumbId: "Home",
            absolutePath: ""
        }
    ];

    //Cycle through all crumbs and build the breadcrumb array partially
    for (const crumb of crumbs) {
        breadcrumbs.push({
            relativePath: crumb.endsWith("/") ? crumb : `${crumb}/`,
            uiFriendlyName: crumb,
            breadcrumbId: "",
            absolutePath: ""
        });
    }

    const currentCrumbs: string[] = [];
    for (let i = 0; i < breadcrumbs.length; ++i) {
        const crumb = breadcrumbs[i];
        //Skip the Home bread crumb
        if (crumb.breadcrumbId === "Home") continue;
        //Add to the nested route without the forward slashes
        currentCrumbs.push(getItemName(crumb.relativePath));

        //Set the id and absolute path after pushing to array and format it too
        crumb.breadcrumbId = `${currentCrumbs.join("/")}/`;
        crumb.absolutePath = `${currentCrumbs.join("/")}/`;
    }

    return breadcrumbs;
}
