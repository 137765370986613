import React, {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {routeNames} from "../../Navigation/routeNames";
import {NavigationItem} from "../../../store/navigation/actions/NavigationActionTypes";
import {setNavigationItems} from "../../../store/navigation/actions/NavigationActions";
import {RootStore} from "../../../store/Store";
import {WithServiceState} from "store-fetch-wrappers";
import DirectoryContainer from "./Components/DirectoryContainer";
import XDriveBreadcrumb from "../../Breadcrumbs/XDriveBreadcrumb";
import {
    goToBreadcrumbByAbsolutePath,
    goToHomeBreadCrumb
} from "../../../store/breadcrumb/actions/BreadcrumbActions";
import {useBreadcrumb} from "../../Hooks/useBreadcrumb";
import {getDirectoriesForLocation} from "../../../store/rawDirectoryList/actions/RawDirectoryListActions";

const ServiceWrapper = WithServiceState(DirectoryContainer);

/** Root Component for the XDrive Page */
const XDrive = () => {
    const dispatch = useDispatch();

    const directoryStore = useSelector((state: RootStore) => state.rawDirectoryList);
    const breadcrumb = useBreadcrumb();

    /** Page Setup */
    useEffect(() => {
        const navItems: NavigationItem[] = [
            {
                name: routeNames.xDrive.name,
                path: routeNames.xDrive.path
            }
        ];

        //Set Nav Items
        dispatch(setNavigationItems(navItems));
    }, []);

    useEffect(() => {
        dispatch(getDirectoriesForLocation(breadcrumb));
        if (breadcrumb === "") {
            dispatch(goToHomeBreadCrumb());
            return;
        }
        dispatch(goToBreadcrumbByAbsolutePath(breadcrumb));
    }, [breadcrumb]);

    return (
        <React.Fragment>
            <div className="page-container mt-4">
                <XDriveBreadcrumb />
                <ServiceWrapper
                    loaderWheelType={"three-ring"}
                    loaderType={"overlay"}
                    showLoadingText={true}
                    placeholderBlockCount={1}
                    {...directoryStore}
                />
            </div>
        </React.Fragment>
    );
};

export default XDrive;
