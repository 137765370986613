import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const RAW_DIRECTORY_LIST_STORE_STATE = createStoreState("raw_directory_list");

interface RawDirectoryListLoading extends StoreServiceData<Array<string>> {
    type: typeof RAW_DIRECTORY_LIST_STORE_STATE.LOADING;
}
interface RawDirectoryListError extends StoreServiceData<Array<string>> {
    type: typeof RAW_DIRECTORY_LIST_STORE_STATE.ERROR;
}
interface RawDirectoryListSuccess extends StoreServiceData<Array<string>> {
    type: typeof RAW_DIRECTORY_LIST_STORE_STATE.SUCCESS;
}

export type RawDirectoryListDispatchTypes =
    | RawDirectoryListSuccess
    | RawDirectoryListError
    | RawDirectoryListLoading;
