import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const SAVE_FILE_STORE_STATE = createStoreState("save_file");

interface SaveFileLoading extends StoreServiceData<string> {
    type: typeof SAVE_FILE_STORE_STATE.LOADING;
}
interface SaveFileError extends StoreServiceData<string> {
    type: typeof SAVE_FILE_STORE_STATE.ERROR;
}
interface SaveFileSuccess extends StoreServiceData<string> {
    type: typeof SAVE_FILE_STORE_STATE.SUCCESS;
}

export type SaveFileDispatchTypes = SaveFileLoading | SaveFileError | SaveFileSuccess;
