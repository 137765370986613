import {
    StoreServiceData,
    ServiceActionTypes,
    createDefaultStoreState,
    createReducer
} from "store-fetch-wrappers/dist";
import {
    UPLOAD_MANAGER_NOTIFICATIONS_STORE_STATE,
    UploadManagerNotificationMessage
} from "../actions/UploadManagerNotificationsActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<UploadManagerNotificationMessage>(null);

const uploadManagerNotificationsReducer = (
    state: StoreServiceData<UploadManagerNotificationMessage> = defaultState,
    action: ServiceActionTypes<UploadManagerNotificationMessage>
): StoreServiceData<UploadManagerNotificationMessage> =>
    createReducer(state, action, UPLOAD_MANAGER_NOTIFICATIONS_STORE_STATE, () =>
        showErrorToast(action.error)
    );

export default uploadManagerNotificationsReducer;
