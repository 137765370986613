import React, {useEffect, useState} from "react";
import {getFileExtensionFromPath} from "../Helpers/directorySortingHelpers";
import FileIcon from "./FileIcon";
import DirectoryItemContext from "../../../Context/DirectoryItemContext";

/** Container for the directory item. Will contain the name and the icon */
const DirectoryListItem = (props: DirectoryListItemProps) => {
    const [fileExt, setFileExt] = useState<string>("");

    useEffect(() => {
        // Ignore the folder type as it isn't relevant to this operation
        if (props.item.endsWith("/")) return;

        setFileExt(getFileExtensionFromPath(props.item));
    }, [props]);
    return (
        <React.Fragment>
            <DirectoryItemContext.Provider value={props}>
                <div className="directoryItemWrapper">
                    <FileIcon fileExtension={props.item.endsWith("/") ? "folder" : fileExt} />
                </div>
            </DirectoryItemContext.Provider>
        </React.Fragment>
    );
};

export default DirectoryListItem;

export interface DirectoryListItemProps {
    item: string;
}
