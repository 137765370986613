import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";
import {UploadManagerFileItem} from "../../../components/UploadManager/Helpers/uploadManagerHelpers";

export const UPLOAD_MANAGER_STORE_STATE = createStoreState("upload_manager");

interface UploadManagerLoading extends StoreServiceData<Array<UploadManagerFileItem>> {
    type: typeof UPLOAD_MANAGER_STORE_STATE.LOADING;
}
interface UploadManagerError extends StoreServiceData<Array<UploadManagerFileItem>> {
    type: typeof UPLOAD_MANAGER_STORE_STATE.ERROR;
}
interface UploadManagerSuccess extends StoreServiceData<Array<UploadManagerFileItem>> {
    type: typeof UPLOAD_MANAGER_STORE_STATE.SUCCESS;
}

export type UploadManagerDispatchTypes =
    | UploadManagerSuccess
    | UploadManagerError
    | UploadManagerLoading;
