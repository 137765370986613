import React, {useEffect, useRef} from "react";
import UploadFileForm from "./UploadFileForm";
import {useHistory} from "react-router-dom";
import {routeNames} from "../../../Navigation/routeNames";
import {useDispatch, useSelector} from "react-redux";
import {useQuery} from "../../../Hooks/useQuery";
import {
    goToBreadcrumbByAbsolutePath,
    goToHomeBreadCrumb
} from "../../../../store/breadcrumb/actions/BreadcrumbActions";
import {getDirectoriesForLocation} from "../../../../store/rawDirectoryList/actions/RawDirectoryListActions";
import {
    nullifyUploadFileListStore,
    setUploadFileListStore,
    validateAndSaveUploadedFiles
} from "../../../../store/uploadedFileList/actions/UploadedFileListActions";
import {RootStore} from "../../../../store/Store";
import {UploadedFile} from "../../../../store/uploadedFileList/actions/UploadedFileListActionTypes";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";

const UploadFileFormContainer = () => {
    const uploadButtonRef = useRef(null);
    const history = useHistory();
    const dispatch = useDispatch();
    const query = useQuery();
    const {data} = useSelector((state: RootStore) => state.uploadedFileList);

    /** Triggers the synthetic click to select a file to upload. */
    const selectFile = () => {
        if (uploadButtonRef && uploadButtonRef.current) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            uploadButtonRef.current.click();
        }
    };

    /** Checks the selected file is within the constraints. Will be rejected if the file is too big. */
    const onInitialFileSelected = (event: any) => {
        // Get file from selection
        const files: FileList | undefined = event?.target?.files;
        //Don't bother executing if there is no file selected.
        if (!files) return;
        if (files.length === 0) return;
        const fileList: UploadedFile[] = getFilesFromFileList(files);
        dispatch(setUploadFileListStore(fileList));
    };

    useEffect(() => {
        return function () {
            dispatch(nullifyUploadFileListStore());
        };
    }, []);

    //Goes back to x drive at the root dir.
    const backToXDrive = () => {
        const dirLoc = query.get("dirLoc");
        const directoryLocation = dirLoc ? dirLoc : "";
        dispatch(getDirectoriesForLocation(directoryLocation));
        if (directoryLocation === "Home Folder/") {
            dispatch(goToHomeBreadCrumb());
            history.replace(routeNames.xDrive.path);
            return;
        }
        dispatch(goToBreadcrumbByAbsolutePath(directoryLocation));

        history.replace(routeNames.xDrive.path);
    };

    const getFilesFromFileList = (list: FileList): UploadedFile[] => {
        const files: UploadedFile[] = [];
        if (list.length === 0) return files;
        for (let i = 0; i < list.length; ++i) {
            const item = list.item(i);
            if (!item) continue;
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            files.push({
                name: item.name,
                file: item
            });
        }
        return files;
    };

    const sendToUploadManager = async () => {
        const dirLoc = query.get("dirLoc");
        if (!dirLoc) return;
        try {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            const saved: boolean = await dispatch(validateAndSaveUploadedFiles(dirLoc));
            if (saved) {
                backToXDrive();
            }
        } catch (e: any) {
            //
        }
    };

    return (
        <React.Fragment>
            {data &&
                data.map((file: UploadedFile, index: number) => {
                    return (
                        <UploadFileForm
                            file={file.file}
                            name={file.name}
                            index={index}
                            key={index}
                        />
                    );
                })}
            <React.Fragment>
                {data && data.length === 0 && (
                    <div
                        className="section-borders mt-3 mb-3 pl-3 pr-3 pt-3 pb-3"
                        style={{minHeight: `150px`}}
                    >
                        <div className="text-center pt-5">
                            <p> No files selected</p>
                        </div>
                    </div>
                )}
            </React.Fragment>
            <input
                ref={uploadButtonRef}
                multiple={true}
                type="file"
                className={"d-none"}
                onChange={onInitialFileSelected}
            />
            {data && data.length > 0 && (
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Save Files"}
                    onClick={sendToUploadManager}
                    colour={ButtonColourOptions.Yellow}
                    roundedCorner
                />
            )}
            <MCButton
                size={ButtonSize.Large}
                innerValue={
                    <React.Fragment>
                        <React.Fragment>
                            {data && data.length > 0 ? (
                                <span>Re upload Files</span>
                            ) : (
                                <span>Upload Files</span>
                            )}
                        </React.Fragment>
                    </React.Fragment>
                }
                onClick={selectFile}
                colour={ButtonColourOptions.Yellow}
                roundedCorner
            />
            <MCButton
                size={ButtonSize.Large}
                innerValue={"Cancel"}
                onClick={backToXDrive}
                colour={ButtonColourOptions.DarkBlue}
                roundedCorner
            />
        </React.Fragment>
    );
};

export default UploadFileFormContainer;
