import React from "react";
import {BreadcrumbItem} from "../../store/breadcrumb/actions/BreadcrumbActionTypes";
import {useDispatch} from "react-redux";
import {goToBreadCrumb} from "../../store/breadcrumb/actions/BreadcrumbActions";
import {getDirectoriesForLocation} from "../../store/rawDirectoryList/actions/RawDirectoryListActions";
import Icon, {IconType} from "../Icon/Icon";

/** Used the represent the level that the user is currently navigated to*/
const XDriveBreadCrumbItem = (props: BreadcrumbItem) => {
    const dispatch = useDispatch();

    /** Allows user to navigate to a specific breadcrumb in the array */
    const goToSpecificCrumb = () => {
        dispatch(goToBreadCrumb(props));
        dispatch(getDirectoriesForLocation(props.absolutePath));
    };

    const getIcon = () => {
        if (props.uiFriendlyName === "Home") {
            return <Icon rootElement={"Span"} icon={IconType.House} size={"Medium"} />;
        }

        return <Icon rootElement={"Span"} icon={IconType.Folder} size={"Medium"} />;
    };

    return (
        <React.Fragment>
            <div className="pulse-breadcrumb-item" onClick={goToSpecificCrumb}>
                <h6 className="mb-0">
                    {getIcon()} {props.uiFriendlyName}
                </h6>
            </div>
        </React.Fragment>
    );
};

export default XDriveBreadCrumbItem;
