import React from "react";
import {toArray} from "../../../utils/sortingUtils";
import {UploadItemState, UploadManagerFileItem} from "../Helpers/uploadManagerHelpers";
import {useDispatch} from "react-redux";
import {
    clearAllCompleteUploads,
    removeItemFromManager,
    updateItemUploadState,
    uploadLargeFile
} from "../../../store/uploadManager/actions/UploadManagerActions";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../Button/MCButton";
import {PulseTable} from "pulse_table";
import {
    getFileExtensionFromPath,
    getItemName
} from "../../Pages/XDrive/Helpers/directorySortingHelpers";
import {getDirectoriesForLocation} from "../../../store/rawDirectoryList/actions/RawDirectoryListActions";
import {useBreadcrumb} from "../../Hooks/useBreadcrumb";
import {goToBreadcrumbByAbsolutePath} from "../../../store/breadcrumb/actions/BreadcrumbActions";
import LoadingState from "./LoadingState";

const UploadManagerContainer = (props: Array<UploadManagerFileItem>) => {
    const dispatch = useDispatch();
    const breadcrumb = useBreadcrumb();

    // Clears all complete uploads from the list
    const clearAllUploads = () => {
        dispatch(clearAllCompleteUploads());
    };

    return (
        <React.Fragment>
            <div className="row ml-0 mr-0">
                <div className="col d-flex justify-content-end pr-0">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Clear All"}
                        onClick={clearAllUploads}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                </div>
            </div>
            <PulseTable
                items={toTableRow(toArray(props), breadcrumb, dispatch)}
                headers={{
                    fileName: "File Name",
                    directory: "Directory",
                    state: "State",
                    actions: "Actions"
                }}
                customRenderers={{
                    state: (item: TableRow) => <React.Fragment>{item.state}</React.Fragment>,
                    actions: (item: TableRow) => <React.Fragment>{item.actions}</React.Fragment>
                }}
                noItemsSection={
                    <div className="row ml-0 mr-0">
                        <div className="col pl-0 pr-0">
                            <p className="mb-0">There are no items being uploaded</p>
                        </div>
                    </div>
                }
            />
        </React.Fragment>
    );
};

export default UploadManagerContainer;

interface TableRow {
    fileName: string;
    directory: string;
    state: JSX.Element[] | JSX.Element;
    actions: JSX.Element[] | JSX.Element;
}

function toTableRow(
    uploadItems: UploadManagerFileItem[],
    breadcrumb: string,
    dispatch: any
): TableRow[] {
    return uploadItems.map((item) => {
        return {
            fileName: getFileName(item),
            directory: getDirectoryFromFile(item),
            state: getStateMarkup(item),
            actions: getActionsMarkup(item, breadcrumb, dispatch)
        };
    });
}

function getFileName(item: UploadManagerFileItem): string {
    const filename = getItemName(item.path);
    const fileExt = getFileExtensionFromPath(item.path);

    return `${filename}${fileExt}`;
}

/** Gets the directory name of file being uploaded. */
function getDirectoryFromFile(item: UploadManagerFileItem): string {
    const fileName = getFileName(item);
    const rawPath = item.path.split(fileName)[0];

    const directoryPath = getItemName(rawPath);
    return directoryPath === "" ? "Home Folder" : directoryPath;
}

function getStateMarkup({
    uploadState,
    percentage
}: UploadManagerFileItem): JSX.Element | JSX.Element[] {
    return <LoadingState state={uploadState} percentage={percentage} />;
}

function getActionsMarkup(
    item: UploadManagerFileItem,
    breadcrumb: string,
    dispatch: any
): JSX.Element | JSX.Element[] {
    const uploadFile = async () => {
        const fileLocation = getFileName(item);
        const rawPath = item.path.split(fileLocation)[0];

        await dispatch(updateItemUploadState(item, UploadItemState.Uploading));

        const success: boolean = await uploadLargeFile(item, rawPath);

        if (success) {
            await dispatch(updateItemUploadState(item, UploadItemState.Complete));

            //After upload completion, we check if the user in the directory the file was uploaded in. if so, we refresh the directory so the user can see the uploaded file
            const fileName = getFileName(item);
            const uploadedDir = item.path.split(fileName)[0];
            if (uploadedDir === breadcrumb) {
                await dispatch(getDirectoriesForLocation(breadcrumb));
            }
            return;
        }
        await dispatch(updateItemUploadState(item, UploadItemState.Failed));
    };

    /** Removes item from upload manager when the item is complete/failed. */
    const removeItemFromUploadManager = async () => {
        dispatch(removeItemFromManager(item));
    };

    const viewItemInDirectory = async () => {
        const fileName = getFileName(item);
        const rawPath = item.path.split(fileName)[0];
        await dispatch(getDirectoriesForLocation(rawPath));
        await dispatch(goToBreadcrumbByAbsolutePath(rawPath));
    };
    return (
        <React.Fragment>
            {item.uploadState === UploadItemState.Incomplete && (
                <MCButton
                    size={ButtonSize.Large}
                    innerValue={"Upload"}
                    onClick={uploadFile}
                    colour={ButtonColourOptions.DarkBlue}
                    roundedCorner
                />
            )}
            {item.uploadState === UploadItemState.Failed && (
                <React.Fragment>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Retry"}
                        onClick={uploadFile}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Clear"}
                        onClick={removeItemFromUploadManager}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                </React.Fragment>
            )}
            {item.uploadState === UploadItemState.Complete && (
                <React.Fragment>
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Clear"}
                        onClick={removeItemFromUploadManager}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"View"}
                        onClick={viewItemInDirectory}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                </React.Fragment>
            )}
        </React.Fragment>
    );
}
