import {ServiceActionTypes, StoreServiceData} from "store-fetch-wrappers";
import {UPLOADED_FILE_LIST_STATE, UploadedFile} from "../actions/UploadedFileListActionTypes";
import {createDefaultStoreState, createReducer} from "store-fetch-wrappers/dist";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultState = createDefaultStoreState<UploadedFile[]>([]);

const uploadedFileListReducer = (
    state: StoreServiceData<UploadedFile[]> = defaultState,
    action: ServiceActionTypes<UploadedFile[]>
): StoreServiceData<UploadedFile[]> =>
    createReducer(state, action, UPLOADED_FILE_LIST_STATE, () => showErrorToast(action.error));
export default uploadedFileListReducer;
