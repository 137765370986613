/** Sorts the naming of the files and folders. */
export function getItemName(path: string) {
    const isDirectory = path.endsWith("/");

    //Return the name of the file
    if (!isDirectory) {
        return getFileNameFromPath(path);
    }
    //Split by "/" and return the directory name before the forward slash
    const directoryNames = path.split("/");
    return directoryNames[directoryNames.length - 2];
}

/** Gets the correct extensions from the file path if an extension exists. Will look for the last "." */
export function getFileExtensionFromPath(filePath: string) {
    return `.${filePath.substring(filePath.lastIndexOf(".") + 1, filePath.length)}` || filePath;
}

/** Gets the correct file name from the file path before the file extension and after the last forward slash */
export function getFileNameFromPath(filePath: string) {
    const slashIndex = filePath.lastIndexOf("/");
    // If the slash index is -1, we want to start the search of the file
    // name at the start of the file Path. Otherwise, we use the slash + 1 (excludes the slash)
    // offset to start the stripping
    // Accounts for any files that will be in the root folder
    const actualStartIndex = slashIndex === -1 ? 0 : slashIndex + 1;
    return filePath.substring(actualStartIndex, filePath.lastIndexOf(".")) || filePath;
}
