import {
    createDefaultStoreState,
    createReducer,
    ServiceActionTypes,
    StoreServiceData
} from "store-fetch-wrappers/dist";
import {BREADCRUMB_STORE_STATE, BreadcrumbItem} from "../actions/BreadcrumbActionTypes";
import {showErrorToast} from "../../../utils/toastUtils";

const defaultCrumb: BreadcrumbItem[] = [
    {
        relativePath: "",
        uiFriendlyName: "Home",
        breadcrumbId: "Home",
        absolutePath: ""
    }
];
const defaultState: StoreServiceData<BreadcrumbItem[]> =
    createDefaultStoreState<BreadcrumbItem[]>(defaultCrumb);

const breadcrumbReducer = (
    state: StoreServiceData<BreadcrumbItem[]> = defaultState,
    action: ServiceActionTypes<BreadcrumbItem[]>
): StoreServiceData<BreadcrumbItem[]> =>
    createReducer(state, action, BREADCRUMB_STORE_STATE, () => showErrorToast(action.error));

export default breadcrumbReducer;
