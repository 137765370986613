import {DefaultApi as XDriveApi} from "../../api/xdrive";

export default class XDriveApiModel {
    private static instance: XDriveApiModel;
    private static xDriveApi: XDriveApi;

    public static getInstance(): XDriveApiModel {
        if (!XDriveApiModel.instance) {
            XDriveApiModel.instance = new XDriveApiModel();
        }

        return XDriveApiModel.instance;
    }

    public static getXDriveApi(): XDriveApi {
        if (!XDriveApiModel.xDriveApi) {
            XDriveApiModel.xDriveApi = new XDriveApi({
                basePath: process.env.REACT_APP_X_DRIVE_API
            });
        }

        return XDriveApiModel.xDriveApi;
    }
}
