import {StoreServiceData} from "store-fetch-wrappers";
import {createStoreState} from "store-fetch-wrappers/dist";

export const UPLOADED_FILE_LIST_STATE = createStoreState("upload_file_list_store");

interface UploadedFileListLoading extends StoreServiceData<UploadedFile[]> {
    type: typeof UPLOADED_FILE_LIST_STATE.LOADING;
}
interface UploadedFileListError extends StoreServiceData<UploadedFile[]> {
    type: typeof UPLOADED_FILE_LIST_STATE.LOADING;
}
interface UploadedFileListSuccess extends StoreServiceData<UploadedFile[]> {
    type: typeof UPLOADED_FILE_LIST_STATE.LOADING;
}

export type UploadFileListDispatchTypes =
    | UploadedFileListSuccess
    | UploadedFileListError
    | UploadedFileListLoading;

export interface UploadedFile {
    name: string;
    file: File;
}
