import {Dispatch} from "redux";
import {UPLOAD_MANAGER_STORE_STATE, UploadManagerDispatchTypes} from "./UploadManagerActionTypes";
import {MultiPartUploadPart, XFile} from "../../../api/xdrive";
import store from "../../Store";
import {
    UploadItemState,
    UploadManagerFileItem
} from "../../../components/UploadManager/Helpers/uploadManagerHelpers";
import {setUploadManagerNotificationMessage} from "../../uploadManagerNotifications/actions/UploadManagerNotificationsActions";
import {
    getFileExtensionFromPath,
    getItemName
} from "../../../components/Pages/XDrive/Helpers/directorySortingHelpers";
import XDriveApiModel from "../../apiModel/XDriveApiModel";
import {UploadedFile} from "../../uploadedFileList/actions/UploadedFileListActionTypes";
import {convertCrumbsToAbsolutePath} from "../../../components/Hooks/useBreadcrumb";
import {getDirectoriesForLocation} from "../../rawDirectoryList/actions/RawDirectoryListActions";
import {clamp} from "../../../utils/mathUtils";
import globalAxios, {AxiosResponse} from "axios";
import SparkMD5 from "spark-md5";
import {showErrorToast} from "../../../utils/toastUtils";

/** Nukes store of all uploads and states. */
export const nullifyUploadManagerStore =
    () => async (dispatch: Dispatch<UploadManagerDispatchTypes>) => {
        dispatch({
            type: UPLOAD_MANAGER_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: []
        });
    };

/** Clears all completed uploads */
export const clearAllCompleteUploads = () => {
    return async (dispatch: Dispatch<UploadManagerDispatchTypes>) => {
        const currentState = store.getState().uploadManager.data;
        if (!currentState) return;
        //Gets all uploads that aren't complete
        const filteredItems = currentState.filter(
            (el: UploadManagerFileItem) => el.uploadState !== UploadItemState.Complete
        );
        dispatch({
            type: UPLOAD_MANAGER_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: filteredItems
        });
    };
};

/** Pushes new item to the upload manager. */
export const pushUploadToManager = (item: UploadedFile, path: string) => {
    return async (dispatch: Dispatch<UploadManagerDispatchTypes>) => {
        const currentState = store.getState().uploadManager.data;
        if (!currentState) return;

        const newPath = path === "Home Folder/" ? item.name : `${path}${item.name}`;
        const newItem: UploadManagerFileItem = {
            file: item.file,
            path: newPath,
            contentType: item.file.type,
            uploadState: UploadItemState.Incomplete,
            meta: {},
            percentage: 0
        };

        const notificationMessage = `${getItemName(item.name)} will begin uploading shortly`;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await store.dispatch(setUploadManagerNotificationMessage({message: notificationMessage}));

        //Start the download after two seconds
        setTimeout(async () => {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(updateItemUploadState(newItem, UploadItemState.Uploading));
            const completed = await uploadLargeFile(newItem, newPath);

            if (completed) {
                //Uploaded
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                await store.dispatch(updateItemUploadState(newItem, UploadItemState.Complete));

                const breadcrumbItems = store.getState().breadcrumb.data;
                if (!breadcrumbItems) return;

                //get the current breadcrumb
                const currentBreadcrumb = convertCrumbsToAbsolutePath(breadcrumbItems);

                //Build the string for where the current file was uploaded.
                const fileName = getItemName(newItem.path);
                const fileExt = getFileExtensionFromPath(newItem.path);

                const uploadedFile = `${fileName}${fileExt}`;
                const uploadedPath = newItem.path.split(uploadedFile)[0];

                //If they are the same, the user is in the same directory, refresh the directory for them
                if (uploadedPath === currentBreadcrumb) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    await store.dispatch(getDirectoriesForLocation(currentBreadcrumb));
                }
                return;
            }

            // Failed upload
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            await store.dispatch(updateItemUploadState(newItem, UploadItemState.Failed));
        }, 2000);

        dispatch({
            type: UPLOAD_MANAGER_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: [...currentState, newItem]
        });
    };
};

/** Removes item from manager */
export const removeItemFromManager = (item: XFile) => {
    return async (dispatch: Dispatch<UploadManagerDispatchTypes>) => {
        const currentState = store.getState().uploadManager.data;
        if (!currentState) return;

        const index = currentState.findIndex((el: UploadManagerFileItem) => el.path === item.path);

        if (index < 0) return;

        currentState.splice(index, 1);

        dispatch({
            type: UPLOAD_MANAGER_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: currentState
        });
    };
};

async function startUpload(xFile: XFile): Promise<string> {
    try {
        const startRequest = await XDriveApiModel.getXDriveApi().saveMultipartFile(xFile);
        const {uploadId} = startRequest.data;
        return uploadId;
    } catch (e: any) {
        //
    }

    return "";
}

export async function uploadLargeFile(
    newItem: UploadManagerFileItem,
    newPath: string
): Promise<boolean> {
    let uploadId;
    const savedParts: MultiPartUploadPart[] = [];

    // Start off the upload
    try {
        uploadId = await startUpload(newItem);
    } catch (e: any) {
        return false;
    }
    // Upload the chunks
    try {
        const chunkSize = 5342880;
        const fileSize = newItem.file.size;
        let partNumber = 0;
        for (let position = 0; position < fileSize; position += chunkSize) {
            partNumber += 1;
            const remaining = fileSize - position;
            const blockSize = Math.min(remaining, chunkSize);
            const block = await newItem.file.slice(position, position + blockSize).arrayBuffer();

            const result = await uploadChunk(uploadId, partNumber.toString(), newPath, block);

            savedParts.push(result.data);

            const percentage = ((position + chunkSize) / fileSize) * 100;
            await store.dispatch(
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                updateItemPercentage(newItem, clamp(percentage, 0, 100))
            );
        }
    } catch (e: any) {
        // Try and let the server cancel the upload entirely
        await cancelUploadSafe(uploadId, newPath);

        showErrorToast("Could not upload file, at least one part failed to upload");
        return false;
    }

    // Complete the upload
    try {
        const completed = await XDriveApiModel.getXDriveApi().completeMultipartFile({
            path: newPath,
            parts: savedParts,
            uploadId
        });

        return completed.status === 200 || completed.status === 204;
    } catch (e: any) {
        // Try and let the server cancel the upload entirely
        await cancelUploadSafe(uploadId, newPath);

        showErrorToast("Could not upload file");
    }

    return false;
}

/** Updates the state of the item from manager */
export const updateItemPercentage = (item: UploadManagerFileItem, percent: number) => {
    return async (dispatch: Dispatch<UploadManagerDispatchTypes>) => {
        const currentState = store.getState().uploadManager.data;
        if (!currentState) return;

        const index = currentState.findIndex((el: UploadManagerFileItem) => el.path === item.path);
        if (index < 0) return;

        currentState[index].percentage = percent;

        dispatch({
            type: UPLOAD_MANAGER_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: currentState
        });
    };
};

/** Updates the state of the item from manager */
export const updateItemUploadState = (item: XFile, newState: UploadItemState) => {
    return async (dispatch: Dispatch<UploadManagerDispatchTypes>) => {
        const currentState = store.getState().uploadManager.data;
        if (!currentState) return;

        const index = currentState.findIndex((el: UploadManagerFileItem) => el.path === item.path);
        if (index < 0) return;

        let message = "";
        switch (newState) {
            case UploadItemState.Complete:
                message = `${getItemName(
                    item.path
                )} has completed its upload. Open the Upload Manager to remove it from the queue.`;

                break;
            case UploadItemState.Failed:
                message = `${getItemName(
                    item.path
                )} could not be uploaded. Open the Upload Manager to retry the request or remove it from the queue.`;
                break;
            case UploadItemState.Uploading:
                message = `${getItemName(item.path)} is currently being uploaded`;
                break;
        }

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        await store.dispatch(setUploadManagerNotificationMessage({message}));

        currentState[index].uploadState = newState;

        dispatch({
            type: UPLOAD_MANAGER_STORE_STATE.SUCCESS,
            error: null,
            loading: false,
            data: currentState
        });
    };
};

function uploadChunk(
    X_UPLOAD_ID: string,
    X_PART_NUMBER: string,
    X_PATH: string,
    body: ArrayBuffer
): Promise<AxiosResponse<MultiPartUploadPart, any>> {
    const url = process.env.REACT_APP_X_DRIVE_API + "/file/multipart/part"; // TODO: Proper injection

    const raw = SparkMD5.ArrayBuffer.hash(body, true);
    const md5 = btoa(raw);

    return globalAxios.post(url, body, {
        headers: {
            "x-upload_id": X_UPLOAD_ID,
            "x-part_number": X_PART_NUMBER,
            "x-path": X_PATH,
            "x-md5": md5
        }
    });
}

export async function cancelUploadSafe(uploadId: string, path: string) {
    try {
        await XDriveApiModel.getXDriveApi().cancelMultipartFile({
            path,
            uploadId
        });
    } catch {
        //
    }
}
