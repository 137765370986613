import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const UPLOAD_MANAGER_NOTIFICATIONS_STORE_STATE = createStoreState(
    "upload_manager_notifications"
);

interface UploadManagerNotificationsLoading
    extends StoreServiceData<UploadManagerNotificationMessage> {
    type: typeof UPLOAD_MANAGER_NOTIFICATIONS_STORE_STATE.LOADING;
}
interface UploadManagerNotificationsError
    extends StoreServiceData<UploadManagerNotificationMessage> {
    type: typeof UPLOAD_MANAGER_NOTIFICATIONS_STORE_STATE.LOADING;
}
interface UploadManagerNotificationsSuccess
    extends StoreServiceData<UploadManagerNotificationMessage> {
    type: typeof UPLOAD_MANAGER_NOTIFICATIONS_STORE_STATE.LOADING;
}

export type UploadManagerNotificationsDispatchTypes =
    | UploadManagerNotificationsSuccess
    | UploadManagerNotificationsError
    | UploadManagerNotificationsLoading;

export interface UploadManagerNotificationMessage {
    message: string;
}
