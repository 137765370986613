import React, {useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useBreadcrumb} from "../../../Hooks/useBreadcrumb";
import {getItemName} from "../Helpers/directorySortingHelpers";
import {CurrentDirectoryItems} from "./DirectoryContainer";
import {createDirectory} from "../../../../store/createDirectory/actions/CreateDirectoryActions";
import MCButton, {ButtonColourOptions, ButtonSize} from "../../../Button/MCButton";
import {DebounceInput} from "react-debounce-input";
import {showErrorToast, showSuccessToast} from "../../../../utils/toastUtils";
import {Loading} from "store-fetch-wrappers";
import {RootStore} from "../../../../store/Store";

const CreateDirectoryForm = (props: CreateDirectoryFormProps) => {
    const [folderName, setFolderName] = useState<string>("");
    const dispatch = useDispatch();
    const breadcrumb = useBreadcrumb();
    const {loading} = useSelector((state: RootStore) => state.createDirectory);

    const saveNewFolderRequest = async () => {
        // Don't allow for the item to be saved if the folder name isn't valid
        const valid = validateName(folderName, props.items);
        if (!valid) return;

        const path = `${breadcrumb}${folderName}`;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const success: boolean = await dispatch(
            createDirectory(
                {
                    path
                },
                breadcrumb
            )
        );

        if (!success) return;

        showSuccessToast("Created Folder");
        props.onClose();
    };

    return (
        <React.Fragment>
            <div className="row ml-0 mr-0">
                <p className="mb-0">Folder Name</p>
            </div>
            <div className="row ml-0 mr-0">
                <DebounceInput
                    placeholder="Enter folder name..."
                    className="input-fields"
                    value={folderName}
                    onChange={(e) => setFolderName(e.target.value)}
                />
            </div>
            {loading ? (
                <div className="row ml-0 mr-0 mt-3">
                    <div className="col justify-content-center text-center">
                        <Loading type={"three-ring"} showLoadingText={false} />
                    </div>
                </div>
            ) : (
                <div className="row ml-0 mr-0 mt-3">
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Save"}
                        onClick={saveNewFolderRequest}
                        colour={ButtonColourOptions.Yellow}
                        roundedCorner
                    />
                    <MCButton
                        size={ButtonSize.Large}
                        innerValue={"Cancel"}
                        onClick={props.onClose}
                        colour={ButtonColourOptions.DarkBlue}
                        roundedCorner
                    />
                </div>
            )}
        </React.Fragment>
    );
};

export default CreateDirectoryForm;

interface CreateDirectoryFormProps extends CurrentDirectoryItems {
    onClose: () => void;
}

export function validateName(incomingFolderName: string, directoryItems: string[]): boolean {
    let valid = true;
    // Check for valid name length
    if (incomingFolderName.length < 2) {
        valid = false;
        showErrorToast("Folder name must be 2 or more characters in length.");
    }

    // Check if a folder exists under that name
    const index = directoryItems.findIndex((el: string) => getItemName(el) === incomingFolderName);
    if (index > -1) {
        valid = false;
        showErrorToast("Folder name is not unique.");
    }

    return valid;
}
