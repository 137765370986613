import {useSelector} from "react-redux";
import {RootStore} from "../../store/Store";
import {BreadcrumbItem} from "../../store/breadcrumb/actions/BreadcrumbActionTypes";

/** Gets all the breadcrumbs that are within the store and flattens them into one string that is
 * suitable for the list request. */
export const useBreadcrumb = () => {
    const defaultCrumb = "";

    const breadcrumbsFromStore = useSelector((state: RootStore) => state.breadcrumb).data;

    if (breadcrumbsFromStore) {
        return convertCrumbsToAbsolutePath(breadcrumbsFromStore);
    }

    return defaultCrumb;
};

/** Flattens the breadcrumbs and turns it into an absolute path */
export function convertCrumbsToAbsolutePath(items: BreadcrumbItem[]): string {
    const crumbs: string[] = [];
    for (const breadcrumb of items) {
        //ignore the empty (home folder path)
        if (breadcrumb.relativePath === "") continue;

        crumbs.push(breadcrumb.relativePath);
    }

    return crumbs.join("");
}
