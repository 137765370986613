/** Filters and returns all items in a specified directory. */
import {getItemName} from "./directorySortingHelpers";

export function filterDirectoriesInTree(
    targetPath: string,
    directoryTree: Array<string>
): Array<string> {
    const filteredPaths: Array<string> = new Array<string>();

    for (const path of directoryTree) {
        //We want to ignore the path we are searching for.
        if (path === targetPath) continue;

        if (targetPath === "") {
            const firstSlashIndex = path.indexOf("/");
            //Accounts for files in the root directory
            if (firstSlashIndex === -1) {
                filteredPaths.push(path);
                continue;
            }
            //Gets the folder that is in the root directory. strips everything off beyond first "/".
            //So "drivers/test/" => "drivers/"
            const directory = path.substring(0, firstSlashIndex + 1);
            //Checks if it has already been added to the filtered list. if so, we ignore it.
            // I.e "drivers/test/" && "drivers/sub/" => would both collapse to "drivers/" creating a duplicate.
            if (filteredPaths.indexOf(directory) > -1) continue;
            //Push to filtered list and continue.
            filteredPaths.push(directory);
            continue;
        }

        if (path.startsWith(targetPath)) {
            const remainingPath = path.substring(targetPath.length, path.length);
            //Get the indexes of the first and last slashes.
            const firstSlashIndex = remainingPath.indexOf("/");
            const lastSlashIndex = remainingPath.lastIndexOf("/");

            // Check to see if there are any nested items after the directory we are looking at. If so, we skip this iteration of the loop
            const containsNestedItems = doesSubstringContainNestedDirectoryItems(remainingPath);
            if (containsNestedItems) continue;

            // If the first slash index doesn't exist, or the last slash is at the same place as the first, we push that to the filter as these are files within this directory.
            if (firstSlashIndex === -1 || lastSlashIndex <= firstSlashIndex) {
                filteredPaths.push(path);
            }
        }
    }
    const folders = sortAlphabetically(true, filteredPaths);
    const files = sortAlphabetically(false, filteredPaths);

    //Combine them after being sorted.
    return [...folders, ...files];
}

/** Filters paths based on if we want folders or files and then sorts Alphabetically */
function sortAlphabetically(isFolder: boolean, filteredPaths: string[]): string[] {
    return filteredPaths
        .filter((path) => (isFolder ? path.endsWith("/") : !path.endsWith("/")))
        .sort((a, b) => getItemName(a).localeCompare(getItemName(b)));
}

/** Checks to see if the path has any nested items past a directory. If so, we will skip it. */
function doesSubstringContainNestedDirectoryItems(path: string) {
    const slashIndex = path.indexOf("/");
    if (slashIndex === -1) return false;

    const nestedItemsPath = path.substring(slashIndex, path.length);
    return nestedItemsPath.length > 1;
}

export function isDirectory(path: string): boolean {
    return path.endsWith("/");
}
