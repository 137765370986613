import {createStoreState, StoreServiceData} from "store-fetch-wrappers/dist";

export const DELETE_DIRECTORY_STORE_STATE = createStoreState("delete_directory");

interface DeleteDirectoryLoading extends StoreServiceData<string> {
    type: typeof DELETE_DIRECTORY_STORE_STATE.LOADING;
}
interface DeleteDirectoryError extends StoreServiceData<string> {
    type: typeof DELETE_DIRECTORY_STORE_STATE.ERROR;
}
interface DeleteDirectorySuccess extends StoreServiceData<string> {
    type: typeof DELETE_DIRECTORY_STORE_STATE.SUCCESS;
}

export type DeleteDirectoryDispatchTypes =
    | DeleteDirectorySuccess
    | DeleteDirectoryError
    | DeleteDirectoryLoading;
